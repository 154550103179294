// ALERTS
// ------------------------------------
.alerts {

	a{
		//width: 25px;
    height: 25px;
		display: inline-block;
		background-size: cover;
		background-repeat: no-repeat;
		margin-right: 10px;

		&.chrome{
			background-image: url($s3_path + "/volunteer/browser_logos/chrome.png");
		}
		&.firefox{
			background-image: url($s3_path + "/volunteer/browser_logos/firefox.png");	
		}
		&.opera{
			background-image: url($s3_path + "/volunteer/browser_logos/opera.png");
		}
	}

	.padding {
		padding: 5px 0;
	}
	.alert {
		min-height: 58px;
	  border-radius: 0 0 6px 6px;
	  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	  margin: 5px 0;
	  font-family: "Roboto Slab", sans-serif;
  	font-size: 16px;
  	border: none;
  	padding-left: 65px;
  	line-height: 25px;

	  &.alert-success {
			background-color: #8bc142;
			color: white;
		} 

		&.alert-warning {
			background-color: #ffb200;
			color: white;
		} 

		&.alert-error {
			position: relative;
			background-color: #f75228;
			color: white;

			&:before {
				position: absolute;
				content: " ";
				top: 15px;
				left: 21px;
				background-image: url($s3_path + "/assets/images/exclamation.svg");
				width: 28px;
			  height: 28px;
			}
		}

		a {
			color: beige;
			text-decoration: underline;
		} 

		a {
			color: beige !important;
			text-decoration: underline;
		}

		.close {
    	right: -10px;
    	margin-top: 4px;
    	background-image: url($s3_path + "/assets/images/close-o.svg");
			width: 24px;
		  height: 24px;
			@include opacity(1);

		  &:hover {
		  	@include opacity(0.6);
		  }

		  span {
		  	display: none;
		  }
		}

	}
}