// STEPS CIRCLE
// ------------------------------------
.steps-circle {
	width: 146px;
	height: 146px;
	border: 4px solid $default-base; //$default-dark;
  display: block;
  text-align: center;
  margin: auto;

  font-size: 60px;
  letter-spacing: -1.6px;
  color: $background-default;
  padding: 25px 0;

  @include border-radius(50%);
}