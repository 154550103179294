.star {

	&.profile-rating {
		margin-left: -24px;
	}
			
	ul{
		list-style: none;
		margin: 0 auto;
    display: block;
    width: 160px;

		li {
			display: block;
			float: left;
			width: 20.5px;
			height: 19.6px;
			background: url($s3_path + "/assets/images/star.jpg") no-repeat 0 0;
			margin-right: 2px;
			overflow: hidden;
			text-indent: -999px;
			position: relative;

			&.star-disabled {
				opacity: 0.5;
			}
		}
	}
}

.rating-value {
	font-size: 1.2em;
}

.rating-group {
	margin-top: 50px;
}

.rating-text {
	text-align: left;
}

.ng-stars{
	margin: 40px 0 0 0;
	ul{
		display: inline-block;
		width: 235px;
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-top: 20px;

		li{
			background-image: url($s3_path + "/assets/images/stars.png");
			background-repeat: no-repeat;
			width: 42px;
			height:41px;
			background-position: right;
			background-size: cover;
			float: right;
			margin:0 2px;
			cursor: pointer;

			span{
				display: none;
			}

			&:hover ~ li, &:hover, &.selected ~ li, &.selected{
				background-position: left;
			}

		}
	}
}