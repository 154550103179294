// TYPOGRAPH
// ------------------------------------
h1, h2, h3, h4, h5, h6, p {
	font-family: $font;
}

p {
	line-height: 1.5;
  letter-spacing: -0.4px;
}

h2 {
	font-size: 30px;
  line-height: 1.1;
  letter-spacing: -0.8px;

	@media (min-width: $screen-sm-min){
		font-size: 44px;
	  letter-spacing: -1.2px;
	}
}

h3 {
	font-size: 20px;
  letter-spacing: -0.5px;
  color: $default-light;
  line-height: 1.1;
  @media (min-width: $screen-sm-min){
		font-size: 40px;
	  line-height: 0.8;
	  letter-spacing: -1.1px;
	}
}

h4 {
	font-size: 18px;
  letter-spacing: -0.5px;
  
	@media (min-width: $screen-sm-min){
		font-size: 28px;
  	letter-spacing: -0.7px;
  }
}