.sidebar {
	display: block;
	padding-bottom: 40px;

	@media(max-width: $screen-sm-max) {
		br {
			display: none;
		}
	}

	@media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
		& {
			margin-bottom: 40px;
		}

		.security-info .line,
		.payment-methods .line,
		.testimonial .line {
			display: none;
		}
	}

	@media(min-width: $screen-md) {
		& {
			padding-left: 40px;
			margin-top: 70px;
		}
		.col-sm-4 {
			width: 100%;
		}
		.sidebar-line {
			display: none;
		}
	}

	h2 {
		font-size: 24px;
		letter-spacing: -0.6px;
		color: $text-color-h3;
		margin-top: 0px;
		margin-bottom: 16px;
	}

	.line {
		width: 100%;
	  	height: 3px;
	  	border-top: solid 1px #d8dcdf;
	  	margin: 60px 0px 55px;

	  	@media(min-width: $screen-md) {
	  		width: 224px;
	  		margin: 30px 0px;
	  	}
	}

	ul {
		padding-left: 20px;
		margin-bottom: 48px;
		list-style: none;

		li {
			font-family: $font;
			font-size: 14px;
			line-height: 1.7;
			color: $text-color-h3;
		}
	}

	.security-info {

		ul{
			padding:0px;
			margin-left: 25px;
			margin-bottom: 40px;
			list-style-image: url($s3_path + "/assets/images/check.svg");
		}
	}

	.payment-methods {

	}

	.testimonial {
		
		@media(min-width: $screen-lg) {
			max-width: 240px !important;
		}

		.image {
			text-align: center;
		}

		.text {
			width: 100%;
			margin-bottom: 22.2px;

			p {
				font-family: $font;
				font-size: 18px;
				line-height: 1.3;
				color: $default-medium;
				text-align: center
			}	
		}
	}

	.regular-info{
		ul{
			padding:0px;
			li{
				a{
					color: $default-hover;
				}
				&:before { 
				  content: '\b7\a0';
				  color: $default-base;
				  padding-right:15px;
				}
			}
		}
	}
}