// ICOMOON
// ------------------------------------
@font-face {
    font-family: 'icomoon';
    src:    url('../assets/fonts/icomoon.eot?aej4ph');
    src:    url('../assets/fonts/icomoon.eot?aej4ph#iefix') format('embedded-opentype'),
        url('../assets/fonts/icomoon.ttf?aej4ph') format('truetype'),
        url('../assets/fonts/icomoon.woff?aej4ph') format('woff'),
        url('../assets/fonts/icomoon.svg?aej4ph#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down2:before {
    content: "\e90f";
}
.icon-arrow-left:before {
    content: "\e910";
}
.icon-arrow-right:before {
    content: "\e911";
}
.icon-arrow-up2:before {
    content: "\e912";
}
.icon-check:before {
    content: "\e913";
}
.icon-chevron-down2:before {
    content: "\e914";
}
.icon-chevron-left2:before {
    content: "\e915";
}
.icon-chevron-right2:before {
    content: "\e916";
}
.icon-chevron-up2:before {
    content: "\e917";
}
.icon-close-o:before {
    content: "\e918";
}
.icon-close:before {
    content: "\e919";
}
.icon-down:before {
    content: "\e91a";
}
.icon-envelope:before {
    content: "\e91b";
}
.icon-exclamation:before {
    content: "\e91c";
}
.icon-minus:before {
    content: "\e91d";
}
.icon-play:before {
    content: "\e91e";
}
.icon-plus:before {
    content: "\e91f";
}
.icon-question:before {
    content: "\e920";
}
.icon-sc-facebook:before {
    content: "\e921";
}
.icon-sc-twitter:before {
    content: "\e922";
}
.icon-search2:before {
    content: "\e923";
}
.icon-trash:before {
    content: "\e924";
}
.icon-up:before {
    content: "\e925";
}
.icon-user2:before {
    content: "\e926";
}

.icon-arrow-down:before {
    content: "\e908";
}
.icon-arrow-up:before {
    content: "\e909";
}
.icon-chevron-down:before {
    content: "\e90a";
}
.icon-chevron-left:before {
    content: "\e90b";
}
.icon-chevron-right:before {
    content: "\e90c";
}
.icon-chevron-up:before {
    content: "\e90d";
}
.icon-logout:before {
    content: "\e90e";
}
.icon-cart:before {
    content: "\e900";
}
.icon-fb:before {
    content: "\e901";
}
.icon-google-plus:before {
    content: "\e902";
}
.icon-pinterest:before {
    content: "\e903";
}
.icon-search:before {
    content: "\e904";
}
.icon-tumblr:before {
    content: "\e905";
}
.icon-twitter:before {
    content: "\e906";
}
.icon-user:before {
    content: "\e907";
}

