// Globall
// ------------------------------------
body {
	font-family: $font !important;
  position: inherit !important; 
  width: 100%; 
  top: inherit !important;
}

hr {
	width: 100%;
  height: 3px;
  border: 0px;
  clear: both;
}

.thanks-angel{
  background-image: url($s3_path + "/assets/images/thank-you-angel-bubble.png");
  margin: 0 auto;
  padding-bottom:180px;
  width: 1140px;
  height:180px;
}

.page {
	min-width: 320px;
  padding: 81px 0 0;
  min-height: 450px;
  @media (min-width: $screen-sm-min){
    min-height: 350px;
  }
}

body.page-job_waiting > #toast-container,
body.modal-open > #toast-container {
  display: none !important;
}

// remove position relative of bootstrap default
.nav li, .navbar{
  position:relative !important;
}

input::-webkit-input-placeholder {
  font-family: $font;
  font-weight: $font-light;

  color: $text-color-placeholder !important;
  font-size: 16px;
}

a {
  &:hover, 
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
    outline: none;
  }
}

.btn-font-14{
  font-size: 14px !important;
}

.no-padding{
  padding:0px !important;
}

md-virtual-repeat-container{
  z-index: 1100 !important;
}

.sign{
  background-image: url($s3_path + '/assets/images/sign.png');
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  display: block;
}

.sign_bubbles{
  background-image: url($s3_path + '/assets/images/bubble.png');
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  display: block;
}

.language_sign{

  input[type="search"]{
    &.selected_sign{
      background-image: url($s3_path + '/assets/images/sign.png');
      background-repeat: no-repeat;
      background-position: right;
      margin-right: 10px;
    }
  }

  &.selected_smaller{
    input[type="search"]{
      &.selected_sign{
        //background-position: 180px;
      }
    }
  }
}

.chat-count {
    position: absolute;
    right: -10px;
    top: -10px;
    
    background-color: $default-dark;
    color: #fff;
    padding: 4px;
    
    @include border-radius(8px);
}

    
@media (max-width: 804px) {

    #cookie_container {
        flex-direction: column !important;
        
        .left {
          float: left;
          margin-bottom: 32px;
        }
    }
}


#cookie_container {
  display: flex;
  flex-direction: row;

  position: fixed;
  bottom: 24px;
  left: 24px;
  right: 24px;
  background-color: $background-menu;
  padding: 32px;
  @include border-radius(15px);
  color: $default-light;

  z-index: 1000;

  font-weight: bold;
  font-size: 14px;

  font-family: $font !important;

  .left {
    float: left;
    margin-right: 32px;

  }
  .right {
    float: right;

    button {
      background-color: #ffffff;
      border: none;
      padding: 16px;
      color: $background-menu;
      @include border-radius(5px);
    }
  }
  .clearfix {
    clear: both;
  }
}