// Volunteer
// ------------------------------------


.volunteer {

    hr { border-top: 1px solid #243440 }
    // HEADER
    .header {
        .navbar {
            .navbar-header {
                .navbar-brand {
                    .oroboo {
                        // background: url($s3_path + '/assets/images/logo-oroboo-angel.svg') no-repeat 0 0;
                        //background: url('/assets/images/logo-oroboo-angel.svg') no-repeat 0 0;
                        background: url('/assets/images/logo-oroboo-angel.svg') no-repeat 0 0; //logo-oroboo-angel-v4.svg
                        width: 260px;
                        height: 57px;
                        margin-top: 13px;
                        @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max){
                            // background: url($s3_path + '/assets/images/logo-oroboo-angel-vertical.svg') no-repeat 0 0;
                            background: url('/assets/images/logo-oroboo-angel-vertical.svg') no-repeat 0 0;
                            width: 130px;
                            height: 50px;
                            margin-top: 13px;
                        }
                        @media (max-width: $screen-xs-max){
                            background: url('/assets/images/logo-oroboo-angel-vertical.svg') no-repeat 0 0;
                            height: 50px;
                            width: 110px;
                            margin-top: 19px;
                        }
                    }
                }
                .register-menu {
                    li {
                        .register-item {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .invert {
        .navbar {
            .navbar-header {
                .navbar-brand {
                    .oroboo {
                        background: url('/assets/images/logo-oroboo-angel-inverse.svg') no-repeat 0 0; //logo-oroboo-angel-inverse-v4.svg
                        @media (max-width: $screen-sm-max){
                            // background: url($s3_path + '/assets/images/logo-oroboo-angel-vertical-inverse.svg') no-repeat 0 0;
                            background: url('/assets/images/logo-oroboo-angel-vertical-inverse.svg') no-repeat 0 0;
                        }
                    }
                }
            }
        }
    }

    // HOME
    &.home {

        section {
            slick {
                min-height: 846px;
                background-color: $background-default;
                @media (min-width: $screen-md){
                    min-height: 719px;
                }
            }
            .options {
                @media (min-width: $screen-md){
                    min-height: 723px;
                }

                @media (min-width: 992px) and (max-width: 1168px){
                    min-height: 723px;
                }

                @media (max-width: 991px){
                    max-height: 850px;
                    height: 850px
                }

                .type-field {
                    margin-bottom: 0;

                    .ng-invalid.ng-touched {
                        border: none !important;
                    }

                    .radios-error,
                    .error {
                        @media (min-width: $screen-md){
                            width: 65%;
                            margin: 0 auto;
                        }

                        md-radio-group {
                            @media (min-width: $screen-md){
                                margin-top: 8px;
                            }
                            @media (max-width: $screen-md){
                                margin-top: 7px;
                            }
                        }
                    }

                    .radios-error {
                        @media (max-width: $screen-xs-max){
                            min-height: 150px;
                        }
                    }
                }

                md-radio-group {
                    display: block;
                    margin: 20px auto 0;
                    @media (max-width: $screen-xs-max){
                        width: 200px;
                    }
                    md-radio-button {
                        display: inline-block;
                        margin: 0 10px !important;
                        @media (max-width: $screen-xs-max){
                            display: block;
                            float: none;
                            text-align: left;
                            margin: 6px 10px !important;
                        }
                    }
                }

                .left-message {
                    .validation-label.label {
                        left: 0 !important;
                        float: left !important;
                        max-width: 43%;
                    }
                }

                .item-message {
                    @media (min-width: $screen-md){
                        margin: -73px 0 0;
                        left: 50%;
                        margin-left: -25%;
                    }
                    @media (max-width: $screen-xs-max){
                        margin: -40px 20px 10px;
                    }

                    .container {
                        @media (max-width: $screen-xs-max){
                            top: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }

        .interpreter {
            .container {
                top: 30%;
                .text {
                    margin-bottom: 80px;
                    h2 {
                        text-shadow: none;
                        color: $default-medium;
                    }
                }
            }
            .form {
                .home-buttons {
                    .button_now {
                        float: none !important;
                        margin-top: 25px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .animation {
            width: 162px;
            height: 162px;
            position: relative;
            display: block;
            margin: 0 auto 30px;

            .globe {
                width: 162px;
                height: 162px;
                left: 0;
                top: 0;
                position: absolute;
                background: url(../../../assets/images/logo_in_.png) no-repeat 0 0;
            }

            .orbit {
                width: 162px;
                height: 162px;
                left: 0;
                top: 0;
                position: absolute;
                background: url(../../../assets/images/logo_.png) no-repeat 0 0;
                -webkit-animation:orbit 2s ease infinite;
                -moz-animation:orbit 2s ease infinite;
                animation:orbit 2s ease infinite;
            }

            @-moz-keyframes orbit { 100% { -moz-transform: rotate(360deg); } }
            @-webkit-keyframes orbit { 100% { -webkit-transform: rotate(360deg); } }
            @keyframes orbit { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


        }

        .job-thanks {
            .thanks-logo p {
                text-align: center;
            }
            .thanks-logo img {
                height: 74px;
            }
            .thanks-title {
                color: #36c188;
            }
            slick {
                min-height: 550px;
                margin-bottom: 0px;
            }
            .container {
                top: 20%;
                .text {
                    color: #c5cbd0;
                    margin-bottom: 0px;
                    padding: 0px 150px;
                }
            }
            .form {
                .home-buttons {
                    .button_now {
                        color: #0f2829;
                        margin-top: 25px;
                        background-color: #36c188;
                        border: solid 1px #36c188;
                        text-transform: none;
                    }
                }
            }
        }

        .job-waiting {
            slick {
                min-height: 558px;
                margin-bottom: 0px;
            }
            .container {
                top: 20%;
                .text {
                    margin-bottom: 0px;
                }
            }
            .form {
                .home-buttons {
                    .button_now {
                        text-transform: none;
                    }
                }
            }
        }

        .job-ready {
            .volunteer-photo {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                -moz-border-radius: 50%;
                border: 1px solid #ccc;
                overflow: hidden;
                margin: 15px auto;
                img {
                    max-width: 200px;
                    max-height: 200px;
                }
            }
            slick {
                min-height: 620px;
                margin-bottom: 0px;
            }
            .container {
                top: 20%;
                .text {
                    margin-bottom: 0px;
                }
            }
            .form {
                .home-buttons {
                    .button_now {
                        text-transform: none;
                    }
                }
            }
        }
    }

    // PROFILE
    &.profile {
        .button-row {
            .btn-cancel-profile, 
            .btn-finish-profile {
                width: 100%;
            }
        }
    }

    &.rtc {
        .webrtc-stats {
            #webrtc-timer, .webrtc-bandwidth, .webrtc-latency {
                .price {
                    display: none !important;
                }
            } 
        }
    }

    .thumbnail-interpreter {
        img {
            width: 85%;
            height: 85%;
            @media (min-width: $screen-md){
                float: right;
            }
        }
        .text-thumb {
            color: #273c4c;
            font-size: 14px;
            @media (min-width: $screen-md){
                width: 200px;
                float: right;
            }
        }
    }

    .ng-stars {
        ul {
            width: 180px;
            li {
                background-image: url("../assets/images/blume.png");
                width: 32px;
                height:41px;
            }
        }
    }

    .share {
        iframe {
            margin: 0 auto;
            width: 109px;
        }
    }

    .center {
        text-align: center;
    }

    .link {
        margin: 24px;
        display: inline-block;
    }

    .content-container {
        min-height: 500px;
        padding-top: 64px;
    }

    .element {
        display:inline-block;
    }

    .element a {
        margin-top: 24px;
    }

    .home {
        > ng-include {
            #alertHolder {
                position: absolute;
                top:81px;
                width:100%;
            }

            .alerts {
                position: relative;
                z-index: 100;
                top: -12px;

                .alert{
                    border-radius: 6px;
                }
            }
        }
    }

    .white_svg {
        filter: invert(1);
    }

}