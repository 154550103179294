.skylink{
  s-header {
    background: #eee;
    padding: 20px;
    font-family: Helvetica, Arial, sans-serif;
  }

  s-header a:first-child {
    float: right;
    margin: 0 0 20px 30px;
  }

  button {
    display: inline-block;
    position: relative;
    top: 110px;
    left: 110px;
    z-index: 10;
  }

  video {
    width: 267px;
    height: 200px;
    border: 1px solid white;
    outline: 1px solid #ccc;
    z-index: 5;
  }

  #myvideo {
    transform: rotateY(-180deg);
  }

  .timer{
    margin: 20px;
  }
}