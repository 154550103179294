

.tabs .nav li {
    min-height: 0px !important;
}

.chat-wrapper {
    display: flex;
}

.send-button {
    position: relative;

    img {
        position: absolute;
        top:15px;
        left:15px;
    }
}

.room_container {
    width:350px;

    .room {
        padding: 20.5px 15px;
        outline: none;
        position: relative;
        z-index:999;
        color: #3b4c59;
        
        .unread_message {
            position: absolute;
            left: 10px;
            top: 10px;

            background-color: $default-dark;
            color: #fff;
            padding: 4px;

            @include border-radius(8px);
        }
        &.active {
            border-top: 1px solid #b4bbc0;
            border-bottom: 1px solid #b4bbc0;
            border-left: 1px solid #b4bbc0;
            margin-right: -1px;

            background-color: #eff1f2;
        }
        &:hover {
            background-color: #eff1f2;
        }
    }
}

.message_container {
    flex:1;

    background-color: #eff1f2;
    padding: 31px 42px;
    border: 1px solid #b4bbc0;
    padding-top: 50px;

    position: relative;

    .menu_bar {
        position: absolute;
        left:0px;
        right: 0px;
        top:12px;
        height: 50px;

        ul {
            float: left;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                width: 220px;
                margin: 25px 0;
                span {
                    display: block;
                    font-size: 14px;
                    line-height: 1.1;
                    color: #414e58;
                    font-weight: 100;
                }
                strong {
                    display: block;
                    font-size: 16px;
                    line-height: 2;
                    color: $background-base;
                    font-weight: 400;
                    padding-left: 22.5px;
                }
            }

            &.actions {
                background-color: white;
                border: 1px solid #e8eaeb;
                height: 42px;
                padding: 0px;
                @include border-radius(5px);
                @media (min-width: $screen-md-min){
                    position: absolute;
                    top: 0px;
                    right: 20px;
                }

                li{
                    width: auto;
                    margin: 0;
                    float: left;
                    border-left: 1px solid #e8eaeb;
                    height: 100%;
                    &:nth-child(1) {
                        border-left: none;
                    }
                    a {
                        color: $background-secondary;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 40px;
                        padding: 0 20px;
                        text-transform: uppercase;
                        display: block;
                    }
                }
            }
        }
    }

    .send-container {
        margin-top: 12px;

        display: flex;
        .send-text {
            flex: 1;
            height: 50px;
            resize: none;

        }

        .send-button {
            height: 50px;
            width: 50px;
            border: 1px solid #aaa;
            background-color: $default-medium;

            cursor: pointer;

            &:hover {
                background-color: $default-light;
            }
        }
    }
}

.message_container_right {
    text-align: right;
    margin-left: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
    .message_right {
        position: relative;
        display:inline-block;
        min-width: 40px;

        text-align: left;

        white-space: pre-line;

        background-color: #fff;
        @include border-radius(10px);
        padding: 10px;
        padding-bottom:18px;

        word-break: break-all;

        .time {
            position: absolute;
            right: 8px;
            bottom: 0px;
            font-size: 12px;
        }

    } 
}

.message_container_left {
    text-align: left;
    margin-right: 100px;
    margin-top: 12px;
    margin-bottm: 12px;
    .message_left {
        position: relative;
        display:inline-block;
        min-width: 40px;

        text-align: left;

        white-space: pre-line;

        background-color: #fff;
        @include border-radius(10px);
        padding: 10px;
        padding-bottom:18px;

        word-break: break-all;

        .time {
            position: absolute;
            right: 8px;
            bottom: 0px;
            font-size: 12px;
        }
    }
}