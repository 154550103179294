// JOB CREATE
// ------------------------------------

.job-create {
	
	form {

		h3 {
			@media (min-width: $screen-sm-min){
				font-size: 14px;
				letter-spacing: 0;
			}
		}

		.form-group {

			.form-input {
				text-align: left;
				@media (min-width: $screen-sm-min){
					width: 100%;
		      max-width: 100%;
		      font-size: 14px;
		    }

		    .radios-error {
		    	height: 54px;
		    	border-radius: 2px;
		    }

		    .middle {
		    	@media (min-width: $screen-sm-min){
						width: 60%;
			    }
		    }

		    md-radio-button {
		    	height: 44px;
		    	outline: none;
		    	cursor: pointer !important;

		    	.md-label {
		    		margin-top: 13px;
		    	}
		    }

		    .date-input, .select-input {
		    	position: relative;
		    	@media (min-width: $screen-sm-min){
		    		float: left;
		    	}
		    }

		    .select-input {
		    	z-index: 2;
		    	height: 45px;
		    }

		    .date-input {
			    position: absolute;
			    right: 0;
			    top: 0;
			    height: 50px;
			    width: 25%;
			    cursor: pointer;

		    	input {
			    	background-color: transparent;
				    box-shadow: none;
				    font-size: 16px;
				    border: none;
				    z-index: 2;
				    text-indent: -9999px;
				    cursor: pointer;
		    	}
		    }

		    #input_selected {
		    	width: 100%;

		    	.md-checked {
		    		@media (max-width: $screen-xs-max){
		    			height: auto !important;
		    		}

			    	.start-date-field {
			    		display: block !important;
			    	}
			    	.date-selected {
			    		position: relative;
	    				top: -12px;
			    	}
		    	}

		    	.start-date-field {
		    		display: none;
		    		height: 44px;
					border-radius: 2px;
				    background-color: #ffffff;
				    border: solid 1px #b4bbc0;
				    padding: 13px 15px;
					font-size: 14px;
					font-weight: 400;
					color: #3b4c59;
					float: right;
					position: relative;
					margin-right: 60px;
					cursor: pointer;
					outline: none;

					@media (max-width: $screen-sm-max){ 
						margin-bottom: 10px;
					}

					.glyphicon {
						width: 46px;
						height: 44px;
						background-color: #d8dcdf;
						position: absolute;
						top: -1px;
						right: -45px;
						display: inline-block !important;
						font-size: 28px;
						color: #2bc287;
						padding: 10px 11px;
					}

					&.start-date-time {
						width: 57px;
					}

					&.start-date-calendar {
						width: 127px;
					}
				}
		    }

		    .radios-error {
		    	.ng-invalid {
		    		&.ng-touched {
		    			border: none !important;
		    		}
		    	}
		    }

		    label {
		    	font-size: 18px;
				  letter-spacing: -0.5px;
				  color: $text-color-h3;
				  font-weight: 400;
				  margin-bottom: 10px;
				  @media (min-width: $screen-sm-min){
						font-size: 14px;
			    }
		    }

		    md-radio-group {
		    	margin-left: 15px;
		    	display: block;
			    .md-label {
			    	span {
				    	font-size: 14px;
		  				color: $background-base;
			    	}
			    }
		    }

			}

		}

		.btn {
			width: 100%;
	    	background-color: $default-hover;
	    	border: none;
	    	@include border-radius(2px);

	    	@media (min-width: $screen-sm-min){
					font-size: 16px;
		    }

		    @media (max-width: $screen-sm-max){
		    	white-space: normal;
				height: auto;
			    padding: 10px 20px;
			    line-height: 32px !important;
		    }

		    &:hover {
		    	background-color: $background-default;
		    }
		}

		#actions {
			margin: 20px 0 50px;

			.btn-cancel-job {
				margin-top: 10px;
				width: 100%;
			}
		}

	} 

	.terms-container {
		background-color: white;
		padding: 10px 40px 10px 20px;
		margin: 1px;
		border: solid 1px $default-gray;

		.scroll-viewport {
			height: 97px;
			width: 100%;	
		}

		.scroll-bar {
			width: 7px;
			right: 10px;
			top: 10px;
			.scroll-thumb {
				width: 5px;
			}
		}

		.terms-checkbox {
			position: absolute;
		    top: 14px;
		    left: 33px;
		}
		.terms-text {
			padding-left: 35px;
		}

		.ng-invalid {
			border: solid 0px transparent !important;
			padding: 0px !important;

			.md-container {
				top: 50% !important;
				left: 0 !important;
			}
		}

		&.checkbox-invalid {
			border: solid 2px $text-color-error !important;
		}

		p {
			font-family: "Roboto", sans-serif;
			font-size: 12px;
		  line-height: 1.5;
		  color: #212121;
		}
	}

	.price_per_minute{
		.prices {
	  	margin: 35px 0 70px;
	  	position: relative;
	  	padding: 0;
	  	list-style: none;
	  	min-height: 160px;
	  	width: 580px;

	  	@media (max-width: $screen-sm-max){
				width: 100%;
				min-width: 320px;
	    }


	  	&:after {
	  		content: " ";
	  		position: absolute;
	  		bottom: -30px;
	  		left: 50%;
	  		margin-left: -40.7%;
    		width: 79%;
	    	height: 20px;
			  background-color: #eff1f2;
			  z-index: 2;
			  @include box-shadow(inset 0 0 5px 0 #d8dcdf);
			  @include border-radius(100px);
	  	}

	  	&.voice, &.video, &.both {
	  		&:before {
	  			content: " ";
	  			position: absolute;
	  			bottom: -24px;
	  			left: 0;
		  		height: 8px;
				  background-color: #39d095;
				  z-index: 3;
				  @include box-shadow(inset 0 1px 3px 0 #a4adb4);
				  @include border-radius(100px);
				  @include transition(all 0.4s ease-out);
	  		}
	  	}

	  	&.video {
	  		&.either {
	  			&:before {
		  			width: 50%;
    				left: 35%;
	  			}
	  		}
	  	}

	  	&.voice {
	  		&.either {
	  			&:before {
		  			width: 50%;
    				left: 10%;
	  			}
	  		}
	  	}

	  	&.both {
	  		&.either {
	  			&:before {
		  			width: 75%;
    				left: 10%;
	  			}
	  		}

	  		&.semi-professional {
	  			&:before {
		  			width: 25%;
    				left: 10%;
	  			}
	  		}

	  		&.professional {
	  			&:before {
		  			width: 25%;
    				left: 60%;
	  			}
	  		}
	  	}

	  	li {
			  position: relative;
	  		float: left;
	  		width: 23%;;
			  height: 148px;
			  padding: 7px 5px;
			  margin: 0 2% 0 0;
	  		font-size: 14px;
	  		text-align: center;
	  		line-height: 1.3;
			  background-color: #eff1f2;
			  @include border-radius(2px);
			  @include transition(all 0.4s ease-out);

			  @media (max-width: 420px){
					font-size: 10px;
					width: 24%;
					margin: 0 1% 0 0;
		    }

			  &:after {
			  	content: " ";
					position: absolute;
					bottom: -9px;
					left: 50%;
					margin-left: -10px;
					width: 20px;
			    height: 20px;
			    background: #eff1f2;
			    @include rotate(45deg);
			  }

			  span {
			  	display: block;
			  	text-align: center;
			  	color: #a4adb4;

			  	&.description {
			  		font-size: 12px;
	  				line-height: 1.2;
	  				@media (max-width: $screen-sm-max){
							height: 56px;
				    }
				    @media (max-width: 420px){
							font-size: 10px;
				    }
			  	}

			  	&.icon {
			  		margin: 10px 0 15px;
			  		@media (max-width: $screen-sm-max){
							margin: 5px 0 5px;
				    }
			  	}

			  	strong {
			  		font-size: 14px;
	  				line-height: 1.0;
	  				@media (max-width: 420px){
							font-size: 12px;
				    }
			  	}
			  }

			  &:before {
			  	content: " ";
			  	position: absolute;
				  width: 20px;
				  height: 20px;
				  background-color: #39d095;
				  bottom: -41.5px;
    			z-index: 3;
				  left: 50%;
				  margin-left: -10px;
				  display: none;
				  @include border-radius(50%);
				  @include box-shadow(0 1px 2px 0 #89959d);
			  }

			  &.active {
			  	background-color: white;
			  	@include box-shadow(0 0 7px 0 #a4adb4);

				  &:after {
				    background: white;
			  		@include transition(all 0.2s ease-out);
				    @include box-shadow(3px 3px 7px -3px #a4adb4);
				  }

				  &:before {
				    display: block;
				  }
			  	
			  	span {
			  		color: #526370;

			  		.ico-icon-audio {
			  			@extend .ico-icon-audio-color;
			  		}

			  		.ico-icon-video {
			  			@extend .ico-icon-video-color;
			  		}
			  	}
			  }

	  	}
		}
	}

	#not-available,
	#request-received-alert {

		p {
			border-radius: 2px;
	  	border: solid 1px #e32931;
	    margin: 20px 40px 0;
	    padding: 13px 20px;
	    font-size: 12px;
  		color: #e32931;
  		text-align: center;
		}

		a {
			margin-top: -2px;
		}
	}

	#request-received-alert {
		p {
			margin-bottom: 20px;
		}
	}

	.sidebar {
		h2 {
			margin-left: 25px;
		}
	}
}