.specification {
		.img-specification{
			height: 94px;
			width: 100%;
			img {
				display: block;
    		margin: auto;
			}
		}

		.col-xs-6 {
			@media (max-width: 460px){
				width: 100%;
			}
		}

		.text-specification {
			margin-bottom: 44px;
			@media (min-width: $screen-md-min){
				margin-bottom: 0;
			}

			h3 {
				font-size: 24px;
				letter-spacing: -0.6px;
				color: $text-color-h3;
				text-align: center;
				line-height: 1.5;
			}
			p {
				width: 100%;
				font-size: 16px;
				letter-spacing: -0.4px;
				color: rgba(72, 92, 108, 0.8);
				text-align: center;
				display: block;
				margin: auto;
				margin-top: 21px;
				padding: 0;
				@media (min-width: $screen-md-min){
					padding: 0 30px;
				}
			}
		}
	}