// BUTTONS
// ------------------------------------
.btn {
	border-radius: 2px;
	font-size: 18px;
	display: block;
	height: 46px;
	padding: 0 20px;
	line-height: 43px !important;
	
	&.default {
		background-color: $default-medium;
		margin: auto;
		color: $text-color-light;
		&:hover {
			background-color: $default-dark;
		}
	}

  &.secondary {
		background-color: $text-color-menu;
		color: $text-color-light;
		&:hover {
			background-color: $default-dark;
		}
	}

  &.third {
    background-color: $background-secondary !important;
    margin: auto;
    color: $text-color-light;

    &:hover, &:focus {
     color: white !important;
     border-color: $background-default !important;
     background-color: $background-default !important;
    }

    &.btn_modal{
      font-size:16px;
    }
  }

  &.cancel_white {
    background-color: white !important;
    cursor: inherit;
    margin: auto;
    color: $text-color-h3 !important;
    border: solid 1px $default-gray !important;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background-color: $default-secondary;
    }
  }

  &.cancel {
    background-color: $default-secondary !important;
    cursor: inherit;
    margin: auto;
    color: $default-gray;
    &:hover {
      background-color: $default-secondary;
    }
  }
}

.action {
  background-color: $default-medium; //$default-hover;
  border: 1px solid transparent;
  color: $text-color-light !important;
  border-radius: 2px;
	margin: 0 10px 0 0;
  padding: 8px 15px !important;
  
  &:hover {
    border: 1px solid $default-medium; //$default-hover;
    background-color: transparent;
    text-decoration: none;
    color: $default-medium !important;
  }
}

.leaked {
  background-color: transparent;
  border: 1px solid $default-base; //$default-medium;
  color: $default-base !important; //$default-medium; 
  border-radius: 2px;
  margin: 0 10px 0 0;
  padding: 8px 15px !important;
  &:hover {
    border: 1px solid transparent;
    background-color: $default-base; //$default-medium;
    color: $text-color-light !important;
    text-decoration: none;
  }
}

.job_profile_cancel_btn{
  display: inline-block;
  margin: 0 10px;
  width: 100%;

  @media (min-width: $screen-md-min){
    width: 218px;
  }
}