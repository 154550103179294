// Header
// ------------------------------------
.header {
  height: 79px;
  position: absolute;
  z-index: 101;
  min-width: 320px;
  width: 100%;
  background: transparent;
  //@include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.2));

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    &.lines {
      height: 3px;
      width: 100%;
      z-index: 1;
      position: relative;
      li {
        width: 25%;
        height: 3px;
        float: left;

        &.light{
          background-color: $default-light;
        }

        &.medium{
          background-color: $default-medium;
        }

        &.base{
          background-color: $default-base;
        }

        &.dark{
          background-color: $default-dark;
        }
      }
    }
  }

  > .bg {
    position: absolute;
    z-index: 0;
    //background-color: $background-default;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: block;

    @include transition(all 0.25s ease-out);
    @include opacity(0.9);
  }

  .nav {
    > li {
      > a:hover,
      > a:focus {
        background-color: transparent;
      }
    }
  }

  .navbar {
    height: 74px;
    margin: 0;
    width: 100%;
    min-width: 320px;
    z-index: 1;
    background: transparent;
    .navbar-header {
      @media (min-width: $screen-sm-min){
        width: 100%;
      }

      .navbar-brand {
        padding: 0 15px;
        .oroboo {
          display: block;
          position: relative;
          margin-top: 7px;
          background: url($s3_path + '/assets/images/oroboo.png') no-repeat 0 0;
          width: 92px;
          height: 55px;
          overflow: hidden;
          text-indent: -999px;
          background-size: contain !important;
          @media (min-width: $screen-sm-min){
            background: url($s3_path + '/assets/images/oroboo-default.png') no-repeat 0 0;
            width: 152px;
            height: 37px;
            margin-top: 20px;
          }
          @media (max-width: $screen-xs-max){
            height: 40px;
            margin-top: 25px;
          }
        }
      }
      
      .navbar-toggle {
        float: none;
        left: 50%;
        position: absolute;
        margin-left: -14px;
        padding: 0;
        margin-top: 23px;
        margin-bottom: 0;
        @media (max-width: 991px) {
          display: block;
        }

        @media (min-width: $screen-sm-min) and (max-width: 992px) {
          margin-top: 21px !important;
        }

        .icon-bar {
          width: 26px;
          height: 3px;
          border-radius: 1px;
          background-color: $default-medium;
        }

        .text {
          width: 28px;
          height: 13px;
          font-weight: 300;
          font-size: 10px;
          color: $text-color-normal;
        }
      }

      .default-menu {
        display: none;
        @media (min-width: 992px){
          margin: 20px 5px 0 0px;
          display: block;
          float: right;
        }

        li {
          &.title-menu {
            display: none;
          }
          &.bg {
            display: none;
          }
          a {

            padding-left: 14px;
            padding-right: 14px;

            font-weight: 400 !important;
            &:hover {
              background-color: none;
            }
          }
        }

        &.open {
          @media (max-width: 991px){
            display: block;
            margin-top: 75px;
            text-align: center;
            position: relative;
            float: none !important;
          }
          @media (min-width: 768px) and (max-width: 991px){
            position: absolute;
            left: 0;
            width: 100%;
          }
          > li {
            @media (max-width: 991px){
              border-bottom: solid 1px rgba(151, 166, 183, 0.4);
              margin: 0 20px;
              position: relative;
              z-index: 2;
              float: none !important;
            }
            @media (min-width: 768px) and (max-width: 991px){
              width: 95%;
              display: block;
              position: relative !important;
            }
            a {
              @media (max-width: 991px){
                font-size: 16px;
                line-height: 3.1;
                color: $default-medium;
                &.item {
                  font-size: 28px;
                  line-height: 2.1;
                  font-weight: 300 !important;
                  padding-top: 25px;
                }
                &:hover {
                  color: white !important;
                }
              }
            }

            &.title-menu {
              @media (max-width: 991px){
                display: block;
              } 

              .ico-close {
                position: absolute;
                right: 20px;
                top: 40px;
                overflow: hidden;
                text-indent: -999px;
              }
            }
            &.bg {
              display: none;
              background-color: $background-base;
              position: absolute !important;
              width: 100%;
              top: 0;
              height: 100%;
              z-index: 0;
              margin: 0;
              @include opacity(0.95);

              @media (max-width: 991px){
                display: block;
              }
            }
          }
        }
      }

      .languages {
        display: none;
        width: 70px;
        margin-left: 20px;
        @media (min-width: $screen-md-min){
          display: block;
        }
        li {
          margin-left: 10px;
          width: 100px; //85px
          a {
            font-size: 14px !important;
            padding-left: 0;
            padding-right: 0;
          }

          ul {
            li {
              padding: 0;
              margin: 0;
              a {
                color: white !important;
              }
            }
          }
        }
        .dropdown-menu {
          min-width: auto !important;
        }
      }

      .register-menu {
        float: right;
        margin: 20px 15px 0 0;
        @media (min-width: $screen-sm-min){
          margin: 15px 0 0;
        }

        li {
          a {
            font-size: 14px;
            color: $background-menu;
            padding: 0 !important;
            margin: 0;
            line-height: 26px;
            @media (min-width: $screen-sm-min){
              padding-top: 8px !important;
              padding-bottom: 8px !important;
            }

            &:hover {
              background-color: transparent;
            }
          }

          .login {
            a {
              line-height: 17px;
              //font-weight: 300;
              @media (min-width: $screen-sm-min){
                line-height: 46px;
              }
            }
          }

          .login-item {
            @extend .leaked;

            @media (max-width: $screen-xs-max){
              background-color: transparent !important;
              border: none !important;

              &:hover {
                border: none !important;
              }
            }
          }

          .register-item {
            @extend .action;

            @media (max-width: $screen-xs-max){
              background-color: transparent !important;
              border: none !important;

              &:hover {
                border: none !important;
                color: $default-dark !important;
              }
            }
          }
        }
      }

      .logged-menu {
        float: right;
        margin: 5px 15px 0 0;
        @media (min-width: $screen-sm-min){
          margin: 20px 0 0;
        }

        .btn {
          background-color: $default-dark;
          color: $text-color-light;
          padding: 6px 12px;
          font-size: 14px;
          height: auto;
          line-height: 1.42857 !important;
          @include border-radius(2px);

          .icon-user2 {
            font-size: 21px;
            vertical-align: text-bottom;
          }

          .name {
            display: none;
            @media (min-width: $screen-sm-min){
              display: inline-block;
              font-family: "Roboto Slab", sans-serif;
              font-weight: 300;
              font-size: 16px;
              padding: 0 10px 0 5px;
              color: $text-color-light;
              text-align: left;
            }
          }
        }

        .open {
          .btn {
            background-color: $background-menu;
            border-radius: 2px 2px 0 0;
          }
        }

        .dropdown-menu {
          background-color: $background-menu;
          border: none;
          position: relative;
          width: 100%;
          
          li {
            position: relative !important;
            &:hover, &.active {
              background-color: $background-default;
              a {
                background-color: transparent;
                border-color: $background-default;
              }
            }

            a {
              color: $text-color-light;
              font-family: "Roboto", sans-serif;
              font-size: 14px;
              line-height: 2.5;
              font-weight: 400;
              margin: 0 10px;
              border-bottom: 1px solid #4ccc9f;
              padding: 0 10px;
              &:hover {
                background-color: $background-default;
                border-color: $background-default;
              }
            }
          }
        }
      }

      .navbar-nav {
        > li {
          &.active{
            a {
              color: $default-medium !important;
            }
          }
          > a {
            //font-weight: 300;
            font-size: 14px;
            color: $text-color-menu;
            padding-top: 8px;
            padding-bottom: 8px;
            &:hover {
              background-color: transparent;
              color: $default-medium;
            }
            &.active, &.focus {
              background-color: transparent !important;
            }
          }
        }
      }

      .dropdown {
        position: relative !important;
        .dropdown-menu {
          position: absolute;
          min-width: 220px;
          @include transition(all 0.4s ease-out);
          @media (max-width: $screen-sm-max){ 
            left: -162px;
          }
          @media (min-width: 768px){ 
            left: 0;
          }
        }
      }
      
    }

    .navbar-submenu {
      display: none;
    }
  }

  &.logged {
    .navbar {

      .navbar-header{
        @media (min-width: $screen-sm-min){
          height: 74px;
        }
        .default-menu {
          li {
            a {
              &:hover {
                color: $background-menu !important;
              }
            }
            &.active {
              a {
                color: $background-menu !important;
              }
            }
          }
          &.open {
            li {
              a {
                &:hover {
                  color: white !important;
                }
              }
              &.active {
                a {
                  @media (max-width: 991px){
                    color: white !important;
                  }
                }
              }
            }
          }
        }
        .logged-menu {
          @media (max-width: $screen-xs-max){
            margin-top: 22px;
          }
        }
      }

      .navbar-submenu {
        display: none;
        height: 44px;
        background-color: $background-default;
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);

        .container {
          @media (max-width: $screen-xs-max){
            padding: 0;
          }
        }

        ul {
          li {
            display: inline-block;            
            text-align: center;
            line-height: 44px;
            font-family: "Roboto", sans-serif;
            font-weight: 700;

            @media (min-width: $screen-sm-min){
              width: auto;
              padding: 0 15px;
            }

            &.active, &:hover{
              position: relative;
              &:before{
                content: " ";
                border-bottom: 3px solid $default-medium;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
              }
            }

            a {
              color: $default-medium;
              line-height: 44px;
              display: block;
              text-transform: uppercase;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

.fixed {
  position:fixed;
}

.invert {
  .navbar {
    .navbar-header {
      .navbar-brand {
        .oroboo {
          @media (min-width: $screen-sm-min){
            background: url($s3_path + '/assets/images/oroboo-inverse.png') no-repeat 0 0;
          }
        }
      }
      .register-menu {
        li {
          .register-item {
            &:hover {
              color: $default-medium !important; //$default-dark 
            }
          }
        }
      }

      .navbar-nav {
        > li {
          > a {
            color: #515f6b;
          }
        }
      }
    }
  }

  > .bg {
    background-color: white;
  }
}