// FORM-CONTAINER
// ------------------------------------

form{

  .input-correct{
    content: url($s3_path + "/assets/images/check.svg");
    position: absolute;
    right: -20px;
    bottom: 17px;
  }

  &#formLogin, &#formRegister{
    .input-correct{
      right: -28px !important;
    }
  }

  .ng-invalid.ng-touched{
    border: solid 2px $text-color-error !important; //solid 2px //$text-color-error //$default-base //$text-color-menu
  }
  
  &.ng-submitted{
    select{
      &.ng-invalid{
        border: none !important;
      }
    }  
  }

  select{
    &.ng-invalid.ng-touched{
      border: none !important;
    }
  }

  .error{

    span{
      background-color: $text-color-error;
      color: white; //white;
      font-size: 10px;
      padding:2px 5px;
      @include border-radius(2px);
      z-index:1;

      //designer way
      float: right;
      margin-right: 7px;
      margin-top: -1px;
      margin-bottom: 2px;

      //jonas way
      //position:absolute;
      //right: 5px;
      //bottom: -17p;
    }

    &.error_radius{
      span{
        margin-top: 5px;
        float:left;
      }
    } 

    &.error_radios{
      span{
        margin-top: -1px;
      }
    } 

    &.error_checkbox{
      span{
        margin-top: -17px;
      }
    } 
  }

  .radios-error{
    border: solid 1px $text-color-error;
    height:40px;
    padding-left: 7px;
    @include border-radius(5px);

    .md-label{
      margin-right: 10px !important;
    }

    &.semi{
      height:55px;

      .error span{
        margin-top: 6px;
      }
    }
  }
    



  &.ng-submitted{
    

    .ng-valid{

    }

    .ng-invalid{
      border: solid 1px $text-color-error !important;
    }

    
  }

  h2{
    font-size:24px;
    color: $text-color-h3;
    text-align:center;
    padding: 0px 0 0 0;
  }

  h3{
    font-size: 15px;
    color: $text-color-h3;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 0px;
  }

  h4{
    font-size: 16px;
    color: #5e6c7d;
    text-align: left;
    margin-bottom: 0px;
  }

  .line-next {
    margin: 0 0px 20px;
    border-top: solid 1px #979797;
    @include opacity(0.3);
  }

  .btn-next{
    line-height: 43px;
    width: 200px;
    height: 44px;
    background: $default-medium;
    border: 0px;
    font-family: $font;
    font-weight: bold;
    color: white;
    font-size: 16px;
    display: block;
    margin: 0 auto;
    @include border-radius(2px);

    &.btn-next-fill{
      width: 100%;
    }

    &.btn-next-license{
      
      float: inherit;
      @media (min-width: $screen-md-min){
        float:right;  
      }
    }

    &.btn-back{
      float: left;
      cursor: pointer;
      text-decoration: none;
    }

    &:hover {
      background-color: $background-default;
    }
  }

  .btn-finish{
    width:80%;
    padding: 5px 0;
    @include border-radius(2px);
    background-color: $default-hover;
    border: 0px;
    font-family: $font;
    font-weight: bold;
    color: white;
    font-size: 16px;
    min-height:44px;

    @media (min-width: $screen-md-min){
      height:44px;
      padding:0px;
    }

    &:hover {
      background-color: $background-default;
    }
  }

  .btn-finish-profile{
    text-align:center;
    line-height: 43px;
    width: 200px;
    height: 44px;
    border: 0px;
    font-family: $font;
    font-weight: bold;
    color: white;
    font-size: 16px;
    display: block;
    margin: 0 auto;
    @include border-radius(2px);
    cursor: pointer;

    background-color: $default-hover;

    @media (min-width: $screen-md-min){
      float:right;
    }

    &.btn-success {
      background-color: $default-medium!important;
    }

    &:hover {
      background-color: $background-default;
    }
  }

  .btn-negative {
    text-align:center;
    line-height: 43px;
    width: 200px;
    height: 44px;
    border: 0px;
    font-family: $font;
    font-weight: bold;
    color: white;
    font-size: 16px;
    display: block;
    margin: 0 auto;
    @include border-radius(2px);
    cursor: pointer;

    background: $default-medium;

    @media (min-width: $screen-md-min){
      float:left;
    }

    background-color: $default-secondary;
    border: solid 1px $default-gray;
    color: $default-gray;

    &:focus, &:hover {
      border: 1px solid $default-gray;
      background-color: transparent;
      color: $default-gray;
    }
  }

  .form-group{
    text-align: center;


    .form-input{
      display: inline-block;
      position: relative;
      width: 90%;
      max-width: 297px;


      @media (min-width: $screen-md-min){
        width: 297px;
        margin:0px;
      }
      
      label{
        text-align:left;
        display:block;
        margin:13px 0 0 0;
        font-weight:500;
        font-size: 16px;
        color: #5e6c7d;

      }

      md-checkbox{
        text-align:left;

        .md-container{
          top: 12px;
        }

        .md-ink-ripple{
          color: $default-base;
        }

        &.md-checked{
          
          .md-icon{
            background-color: $default-base;
          }
        }
      }

      md-radio-button.md-default-theme.md-checked .md-ink-ripple, md-radio-button.md-checked .md-ink-ripple{
        color: $default-base;
      }
      md-radio-button{
        float:left;
        margin-bottom:0px;
        position: relative;
        z-index: 10;

        .md-container:before {
          display:none;
        }

        .md-off{
          border-color: $background-secondary;
          width:27px;
          height:27px;
          border-width: 3px;
        }

        .md-on{
          top: 4px;
          left: 4px;
          width: 19px;
          height: 19px;
          background-color: $background-secondary;
          
        }

        .md-label{
          color:#FFF;
          font-family: $font;
          font-size:16px;
          margin-left: 35px;
          margin-right: 15px;
          margin-top: 7px;
        }

        &.md-checked .md-on{
          transform: scale(0.9);
        }

        &:not(:first-child){
          margin:0px;
        }
      }
      md-datepicker{
        height: 44px;
        line-height:44px;
        font-family: $font;
        font-weight: $font-light;
        color: $text-color-placeholder !important;
        font-size: 16px;
        @include border-radius(6px);
        padding: 0 20px;
        background-color:white;
        border: 1px solid #ccc;
        margin:0px;
        text-align: left;
        display:block;

        .md-datepicker-input{
          color:$text-color-placeholder !important;
          font-size:16px;
        }

        .md-datepicker-input-container{
          margin:0px;
        }

        .md-datepicker-button{
          position: absolute !important;
          right: 0px !important;
          top: 2px;
        }

        .md-datepicker-triangle-button{
          display: none;
        }
      }

      md-autocomplete {
        height: 44px;
        background-color: rgba(0,0,0,0.2); //transparent; //white;
        @include border-radius(2px);

        md-autocomplete-wrap {
          box-shadow: none;
          border: none;
          height: 41px;

          input {
            border: none;
            line-height: 44px;
            height: 44px;
            font-size: 16px;
            padding: 0 20px;
            z-index: 2;
            color: black; //red;
            //font-weight: 300;

            &.ng-invalid {
              border: none !important;
            }
          }

          md-icon {
            display: none;
          }
        }

        button {
          z-index: 2;
        }
      }

      .custom_selctbox{
        position:relative;
        //background-color:red;
        background-color:white;
        border: 1px solid $default-gray; //white;
        @include border-radius(2px);
       
        .select-box{
          color: #8696a9;
          //background-color:red !important;
        }
     
        .custom-selectbox-subpages{
            //background-color:red !important;
            border: 2px solid $default-gray; 
        }

        select{
          height: 44px;
          line-height:44px;
          font-family: $font;
          font-weight: $font-light;
          color: black;
          font-size: 16px;
          padding: 0 20px;
          background:transparent;
          margin:0px;
          text-align: left;
          position:relative;
          -moz-appearance: none;
          appearance: none;
          -webkit-appearance: none;
          position:relative;
          z-index:2;
          border:0px;

          &:disabled{
            background:  #dddddd !important; 
          }

          &.semi-controll:disabled{
            background-image: url($s3_path + '/assets/images/sign.png')  !important;
            background-repeat: no-repeat !important;
            background-position: 260px !important;
            margin-right: 10px;
            background-color: #dddddd !important;
          }

          &:invalid{
            color: $text-color-placeholder;
          }

          &.default{
            width: 100%;
          }
        }

        span{
          display: block;
          -webkit-align-items: flex-end;
          -ms-flex-align: end;
          align-items: flex-end;
          text-align: end;
          width: 24px;
          margin: 0 4px;
          -webkit-transform: translate3d(0, 1px, 0);
          transform: translate3d(0, 1px, 0);
          position:absolute;
          right:20px;
          top:11px;
          z-index:0;
          display:block;
          height: 35px;

          &:after{
            color: white !important;//#2aada7;
            opacity:1 !important;
            -webkit-transform: scaleY(0.5) scaleX(0.8);
            transform: scaleY(0.5) scaleX(0.8);
            border-left: 1px solid #ccc;
            padding-left: 12px;
            display: block;
            content: '\25BC';
            position: relative;
            top: -6px;
            left: 8px;
            speak: none;
            -webkit-transform: scaleY(0.6) scaleX(1);
            transform: scaleY(0.6) scaleX(1);
            padding-top: 9px;
            height: 35px;
          }

          &.default:after{
            color: $green-select !important;
          }
        }

        

      }

      .custom_autocomplete{
        md-autocomplete{
          background-color: white;

          input{
            font-weight: $font-light;
          }
        }

        span{
          &:after{
            color: $green-select !important;
          }
        }
      }
      
      md-select{

        height: 44px;
        line-height:44px;
        font-family: $font;
        font-weight: $font-light;
        color: $text-color-placeholder !important;
        font-size: 16px;
        @include border-radius(2px);
        padding: 0 20px;
        background-color:white;
        border: 1px solid #ccc;
        margin:0px;
        text-align: left;

        .md-text{
          color: $text-color-placeholder !important;
        }

        .md-select-value{
          border:0px;
          transform: translate3d(0, 0px, 0);
          color: $text-color-placeholder !important;
          *:first-child{
            transform: translate3d(0, 0px, 0) !important;          
          }


          &.md-select-placeholder{
            color: $text-color-placeholder;

          }

          .md-select-icon:after{
            color: $default-base;  
            top: 0px;
            transform: scaleY(0.5) scaleX(0.8);
            border-left: 1px solid #ccc;
            left: 8px;
            padding-left: 14px;
          }
        } 
        
      }

      &.has-error{
        .label{
          position: absolute;
          right: 3px;
          bottom: 3px;
        }
      }

      >input{
        height: 44px;
        line-height:44px;
        font-family: $font;
        font-weight: $font-light;
        color: black !important;
        font-size: 16px;
        @include border-radius(2px);
        display: inline-block;
        padding: 0 20px;
        width:100%;
        border: solid 1px#ccc;
      }
    }
  }

  .form-link{

    margin:20px 0 0 0;

    a{
      font-size: 16px;
      color: $default-medium !important;
      font-family: $font;

      &:hover{
        text-decoration:underline;
      }
    }
  }

  .form-fotter{
    width: 100%;
    text-align: center;

    button{
      background: $background-secondary;
      border: 0px;
      height: 48px;
      font-family: $font;
      font-weight: bold;
      @include border-radius(2px);
      color: white;
      font-size: 20px;
      width: 90%;
      max-width: 297px;

      @media (min-width: $screen-md-min){
        width: 297px;
        margin: 0px;
      }

      &:hover {
        background: transparent;
        color: $background-secondary;
        border: 1px solid $background-secondary;
      }
    }

    .text{
      width: 90%;
      max-width: 297px;
      display:inline-block;
      margin:20px 0 0 0;

      @media (min-width: $screen-md-min){
        width: 297px;
      }

      p{
        color: white;
        text-align: left;
        line-height:18px;
        font-size:14px;

        a{
          line-height:inherit !important;
          color: $default-medium !important;
        }
      }
    }

  }
  

  .phone{

    .item {
      position: relative;
      float: left;
      &.small {
        width: 45%;
        margin-right: 5%;
      }
      &.middle {
        width: 50% !important;
      }
      .custom_selctbox { 
        float: left;
        height: 44px;

        select{
          padding: 0 50px 0 10px !important;
        }
      }

      input {
        height: 47px;
        padding-left: 8px !important;
        padding-right: 8px !important;

        line-height: 47px;
        font-family: "Roboto Slab", sans-serif;
        font-weight: 300;
        color: black !important;
        font-size: 16px;
        border-radius: 2px;
        display: inline-block;
        width: 100%;
        border: solid 1px #ccc;
      }
    }

  }

  
  //file upload
  .button-fileupload {
    height: 44px;
    width: 200px;
    border-radius: 2px;
    background-color: $default-medium;
    font-size: 16px;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;

    &:hover{
      background-color: $background-default;
    }
  }


  .list-files{
    display: inline-block;
    text-align: left;
    @media (min-width: $screen-md-min){
      
    }

    .files-content{
      background-color: rgba(59, 76, 89, 0.12);
      width: 255px;
      height: 38px;
      margin: 10px 0;
      span{
        display: inline-block;
        margin: 8px 15px;
        height: 21px;
        font-family: $font;
        font-size: 16px;
        max-width: 200px;
        overflow: hidden;
        > a{
          color: #5e6c7d; 
          max-width: 170px;
          display: block;
          overflow: hidden; 

          &:hover{
            text-decoration: underline;
          }
        }
      }
      > a{
        float: right;
        width: 22px;
        height: 22px;
        border: #89959d solid 1px;
        border-radius: 50px;
        text-align: center;
        color: #89959d;
        margin: 8px 8px 0px 0;
      }
    }
  }

  .files{

    width: 255px;
    color: #89959d;
    display: inline-block;

    .file-content{
      background-color: rgba(59, 76, 89, 0.12);
      margin: 10px 0 0 0;
      width: 255px;
      height: 38px;

      .file-name{
        float: left;
        width: 100px;
        overflow: hidden;
        margin: 10px 0 0 10px;
      }
      .file-size{
        float: left;
        width: 82px;
        overflow: hidden;
        margin: 10px 0 0 10px;
      }
      .file-progress{
        float: left;
        margin: 10px 0 0 10px;
      }
      .file-success{
        float: left;
        margin: 10px 0 0 10px;
      }
      .file-error{
        float: left;
        margin: 10px 0 0 10px;
      } 
    }
  }

  .file-error{
    text-align: right;
    span{
      float: right;
      margin-top: -1px;
      margin: 0px;
      display: inline-block;
    }
  }

  .drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 200px;
    height: 65px;
    text-align: center;
    padding-top: 25px;
    margin: 10px;
  }
  .dragover {
    border: 5px dashed blue;
  }

  .cropArea {
    background-color: #E4E4E4;
    overflow: hidden;
    width: 100%;
    height: 238px;
    margin: 0 0 13px 0;
    display: inline-block;
    border: 5px dashed #DDD;
    position:relative;

    img-crop{
      position: relative !important;
      z-index: 10 !important;
    }

    .selcted-image{
      left: 0px;
      top: 15px;
      position: absolute;
      z-index: 0;
      text-align: center;
      width: 100%;

      .image_content{
        display: inline-block;
        border-radius: 190px;
        height: 200px;
        width: 200px;
        background-size: cover;
        background-position: center center;

        
      }


    }

    .close_image{
      position:relative;
      z-index: 100;
      display: block !important;
      margin-top: 4px;
      margin-left: 5px;
      background-image: url($s3_path + "/assets/images/close-o.svg");
      width: 24px;
      height: 24px;
      filter: alpha(opacity=100);
      opacity: 1;
      cursor: pointer;
    }
  }

  .user-image {
    &.form-group {
      text-align: left;
    }
    .btn-finish-profile{
      display: inline-block;
      float: initial;
      line-height: 30px;
      font-size: 14px;
      width: 170px;
      height: 35px;
    }

    .button-fileupload{
      font-size: 14px;
      width: 150px;
      height: 35px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .instructions {
      position: absolute;
      z-index: 10;
      width: 280px;
      text-align: center;
      left: 37px;
      top: 90px;
    }
  }



  .title-payment{
    h3{
      line-height: 21px;
    }

    @media (min-width: $screen-lg-min){
      margin-left: 32px;
    }
  }


  .certified-content{

    input[type="text"]{
      height: 33px;
      line-height: 33px;
      font-family: $font;
      font-weight: 300;
      color: black !important;
      font-size: 16px;
      border-radius: 2px;
      display: inline-block;
      padding: 0 20px;
      width: 100%;
      border: solid 1px $default-gray;
      margin-top: 10px;
    }

    a{

      height: 25px;
      width: inherit;
      border-radius: 2px;
      background-color: $default-medium;
      font-size: 16px;
      color: #ffffff;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      cursor: pointer;
      text-align: center;
      margin: 10px 0;
      display: block;
    }
  }

  //end form

}

.form-container {
  background-color: #f9fafa;
  margin-bottom: 50px;

  &.form-container-white{
    background-color: white;

    .container{
      padding:0px !important;
    }
  }

  .alerts {
    .container {
      width: 100%;
    }
  }

  .md-label{
    color: #5e6c7d !important;
    font-size: 16px;
  }

  .form-group{

    &.one-line{
      width:100% !important;
      display:block;
    }
  }
}

.bubbles{
  border: solid 2px $text-color-error;
  @include border-radius(4px);
  padding:10px;
  background: white;
  position: relative;
  color: $text-bubble;
  display: inline-block;
  @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.5));

  @media (min-width: $screen-md-min){
    width: 292px;
  }

  p{
    position: relative;
    z-index:2;
    background: white;
    font-size: 13px;
    text-align:left;
    color: $background-base;
  }
  

  &:before {
    content: "";
    position: absolute;
    background-color: white;
    text-align: left;
    width: 25px;
    height: 25px;
    border-top-right-radius: 30%;
    -webkit-transform: rotate(-54deg) skewX(-20deg) scale(1, 0.866);
    transform: rotate(-54deg) skewX(-20deg) scale(1, 0.866);
    border: solid 2px $default-hover;
    left: 31px;
    top: -10px;
    @include box-shadow(-4px 0px 0px 1.05px rgba(0, 0, 0, 0.3));

    @media (min-width: $screen-md-min){
      left: -9px;
      top: 20px;
      -webkit-transform: rotate(-150deg) skewX(-20deg) scale(1, 0.866);
      transform: rotate(-147deg) skewX(-20deg) scale(1, 0.866);
    }
  } 

  &:after {
    content: "";
    position: absolute;
    background-color: white;
    width: 32px;
    height: 30px;
    left: 26px;
    top: 0px;

    @media (min-width: $screen-md-min){
      left: 0px;
      top: 19px;
    }
  }

  &.smaller{
    padding: 10px 0 0 8px;

    &:before {
      @media (min-width: $screen-md-min){
        top: 8px;
      }

    }
    &:after {
      @media (min-width: $screen-md-min){
        top: 7px;
      }

    }
  }

}

.languages_form{
  margin:5px 0 0 0;
  clear:both;
  position: relative;
}

.add_language{
  margin-top: 15px;
  cursor: pointer;

  span{
    float: left;
    margin: 10px;
  }

  a{
    display: inline-block;
    width: 40px;
    height: 40px;
    @include border-radius(2px);
    background-color: #9ea6b0;
    font-size: 40px !important;
    color: #FFF;
    line-height: 35px;
    font-weight: 300;
    float: right;

    &:hover, &:focus {
      text-decoration: none;
      background-color: $background-default;
    }
  }



}

.remove_language{
  margin-top: 3px;
  position: absolute;
  left: -45px;
  cursor: pointer;

  :hover{
    text-decoration:underline;
  }

  span{
    float: left;
    margin: 10px;
  }

  a{
    display: inline-block;
    width: 40px;
    height: 40px;
    @include border-radius(2px);
    background-color: #9ea6b0;
    font-size: 40px !important;
    color: #FFF;
    line-height: 35px;
    font-weight: 300;

    &:hover, &:focus {
      text-decoration: none !important;
      background-color: $background-default;
    }
  }
}
.second_languages_form{
  margin-left: 45px;
}

.license_form{

  .form-input{
    width: 100% !important;
    max-width: 100% !important;
  }

  .bubbles{
    width:100% !important;
    margin:20px 0;
    padding:15px;

    &:before{
      left: 31px;
      top: -10px;
      @include box-shadow(0px -1px 0px 0px rgba(0, 0, 0, 0.3));
      -webkit-transform: rotate(-54deg) skewX(-20deg) scale(1, 0.866);
      transform: rotate(-54deg) skewX(-20deg) scale(1, 0.866);
    }

    &:after{
      left: 30px;
      top: 0px;
    }
  }
}

md-select-menu.md-default-theme md-option[selected], md-select-menu md-option[selected]{
  color: $text-color-placeholder !important;
}

md-option{
  color: $text-color-placeholder !important;
}

.md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator{
  background: $default-base;
}

.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator{
  border: 1px solid $default-base;
}

.md-datepicker-open .md-datepicker-calendar-icon{
  fill: $default-base; 
}

.label-danger {
    background-color: $text-color-error;
    position: absolute;
    right: 0px;
    bottom: -16px !important;
}
.form-control{
  color: black;
  font-family: $font;
  font-weight: 300;
  font-size: 16px;
  //opacity:0.2;
  @include box-shadow(none);
}

.has-error .form-control{
  border-color: $text-color-error;
}

.input-left{
  width:100%;
  text-align:left;

  @media (min-width: 1200px){
    margin: 0 10px;
    
  }
}

form.ng-submitted .radio_group{
  &.ng-invalid{
    border:0px !important;
  }
}

form.ng-submitted md-checkbox.ng-invalid, form md-checkbox.ng-invalid.ng-touched {
  padding:5px 5px 7px 5px;

  .md-container{
    top:17px !important;
    left:7px;
  }
}

md-checkbox.md-default-theme.md-checked.md-focused .md-container:before, md-checkbox.md-checked.md-focused .md-container:before {
    background-color: rgba(0,0,0,0.54);
    @include opacity(0.3);
}

.semi-profissional-form{
  .align-left{
    text-align: left;
  }

  md-radio-button{

     .md-label{

      margin-right: 7px !important;
      margin-left: 29px !important;
      span{
        font-size:14px;
        float:left;
        text-align: left;
      }
    }
  }

  .special{
    float:left;

    md-radio-button{

     .md-label{
        pointer-events: auto;

        input{
          width: 32px;
          float: left;
          margin: 0 5px;
          height: 40px;
          text-align: center;
          padding-left: 1px;
          padding-right: 1px;
        }
        span{
          float:left;
          line-height:40px;
        }
      }
    }
  }

  .line {
    height: 3px;
    border-bottom: solid 1px #cacaca;

  }
}

//Fonenumber plugin changes
.intl-tel-input{
  display: block;
  input{
    height: 44px;
  }
}
.country-list{
  z-index: 10 !important;

  li{
    text-align: left !important;
  }
}

.birthdate{
  
  span{
    &:after{
      padding-left: 0px !important;
      margin-left: 4px !important;
      left: 14px !important;
    }
  }

  .has-error {
    border: solid 2px $text-color-error !important;
    @include border-radius(4px);
  }
  
  select{

    padding: 0 8px !important;

  }
  .day{
    float:left;
    width:29%;
    margin-right: 2%;
  }

  .month{
    float:left;
    width:35%;
    margin-right: 2%;
  }

  .year{
    float:left;
    width:32%;
  }



}

form.ng-submitted ng-form.ng-invalid{
  border: 0px !important;
}

md-radio-group{
  position: relative;
  z-index: 0;

  &.ng-invalid {
    width: 100%;
    height: 40px;
    display: block;
    padding-left: 5px;
    border-radius: 2px;
  }
}

.method-paypal, .method-credit-card{
  .form-input{
    margin-top: 10px !important;
  }
}

.method-credit-card{

  @media (min-width: $screen-md-min){
    padding: 0 50px;
  }

  .form-margin-0{
    margin: 0px !important;
    padding: 0px !important;
  }
  
  h3{
    margin-top: 0px !important; 
    font-size: 15px !important;
  }

  .birth-fields {

  }

  select{
    font-size: 12px !important;
  }

  .custom_selctbox{
    span{
      right: 16px !important;
      top: 10px !important;
      font-size: 12px !important;
      width: 13px !important;

      &:after{
        border-left: 0px !important;
      }
    }
  }

  .card-code{
    
    h3{
      margin-top: 10px !important;
    }

    @media (min-width: $screen-md-min){
      margin-left: 10px;
      
      h3{
        margin-top: 0px !important;
      }
    }

    input{
      height: 44px;
      line-height: 44px;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 300;
      color: black !important;
      font-size: 16px;
      border-radius: 2px;
      display: inline-block;
      padding: 0 20px;
      width: 100%;
      border: solid 1px #ccc;
    }
  }

  .radios-error{
    height: 46px !important; 

    md-radio-group{
      border: 0px !important;
    }
  }
}

.payment-btn{
  text-transform: uppercase;
  padding: 0px !important;
    font-size: 11px !important;
    
  @media (min-width: $screen-md-min){
    font-size: 14px !important;
  }
}

.file-upload-border-error{
  
  display: inline-block;

  .file-error-content{
    border: solid 2px $text-color-error;
    padding: 4px;
  }
}