body {
    background-color: transparent;
    padding: 0px !important;
}

#dark-header {
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    height: 140px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}

.band {
    .container {
        margin-top: 32px;
        text-align: center;
        .middle {
        }
    }
}

.home {
    .badge-header{
        z-index: 9999;
        position: absolute;
        height:130px;
        width:130px;
        background: transparent url($s3_path + "/assets/images/oroboo-angel-free-badge4.png") no-repeat;
        top: 0;
        right: 0;
        display:block;
        border-radius:0;
    }


    &.volunteer {
        section.interpreter {
            slick {
                margin-bottom: 0px;
            }
        }
    }

    section {
        height: 100%;
        width: 100%;

        input::-webkit-input-placeholder {
            color: #fff !important;
        }
        input:-moz-placeholder {
            /* FF 4-18 */
            color: #fff !important;
        }
        input::-moz-placeholder {
            /* FF 19+ */
            color: #fff !important;
        }
        input:-ms-input-placeholder {
            /* IE 10+ */
            color: #fff !important;
        }

        #video-container{
            min-height: 615px;

            @media (min-width: $screen-md){
                min-height: 847px;
            }
            @media (min-width: 992px) and (max-width: 1168px){
                min-height: 647px;
            }

            @media (max-width: 991px){
                max-height: 619px;
            }

            #video-background{
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: -100;
                transform: translateX(-50%) translateY(-50%);
                background: no-repeat;
                background-size: cover;
                transition: 1s opacity;
            }

            /*.scsf-image-pattern {
                    background: url($s3_path + "/assets/images/overlay.png") repeat 0 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;*/
            //}
            .scsf-image-pattern {
                //http://www.patternify.com/ Backgrounds:
                //Punkte
                //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC) 0 0;
                //Punkte Motiv 2
                //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAECAYAAABGM/VAAAAAHUlEQVQYV2NkYGD4z8DAwMiABFA4MHFkQbgOrCoBbx4CBatVfpIAAAAASUVORK5CYII=) 0 0;

                //Rauten durch querverlaufende Linien
                //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAaElEQVQoU12PUQ2AQAxD32SADZCBDmwQbKADGWADZBwpbMvCfW1Z+9ozYABOvrcAG3D73swHiSZg9b0DLsBCIOdeSA3oRZJAx3CKdMgZJA3CZSYw1k6pjMx/pxBkZvnN20kCHStprp0eilwX06rvVloAAAAASUVORK5CYII=) 0 0;

                //querverlaufende Linien
                //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==) 0 0;

                //Rauten mit Punkten gefüllt
                //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAATklEQVQoU2NkYGAwZmBgOMuAACA+CKCIMSIpADGRNaEYgKwQ3WQUjTCF6CYhWw2WAynEpgjmIpg7jUlSiM0TWK2GWUOUZ7ApxggeogIcABHJFtftKVfJAAAAAElFTkSuQmCC) 0 0;

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }


        .slick {
            height: 100%;
            width: 100%;
            min-height: 615px;
            position: relative;
            overflow: hidden;
            @media (min-width: $screen-md){
                min-height: 842px;
            }

            .slick-prev, .slick-next {
                width: 55px;
                height: 55px;
                &:before {
                    display: none;
                }
            }

            .slick-prev {
                left: -12px;
                background: url($s3_path + "/assets/images/to-left.svg") no-repeat;
                z-index: 100;
                @media (min-width: $screen-md){
                    left: 20px;
                }
            }
            .slick-next {
                right: -13px;
                width: 49px;
                height: 52px;
                background: url($s3_path + "/assets/images/to-right.svg") no-repeat 0 2px;
                z-index: 100;
                @media (max-width: 360px){
                    right: -8px;
                }
                @media (min-width: $screen-md){
                    right: 20px;
                }
            }

            .slick-list {
                @media (max-width: 992px){
                    // position: absolute;
                    // min-height: 400px;
                }
            }

        }

        .options {
            height: 100%;
            width: 100%;
            min-width: 320px;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            z-index: 99;
            @media (min-width: $screen-md){
                min-height: 847px;
            }

            @media (min-width: 992px) and (max-width: 1168px){
                min-height: 647px;
            }

            @media (max-width: 991px){
                max-height: 619px;
            }

        }

        .item {
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
            justify-content: center;
            display:flex;


            p {
                @media (max-width: 992px){
                    height: 600px;
                }
            }

            .image {
                width: 100%;
                height: 100%;
                overflow: hidden;
                object-fit: cover;
                min-height: 615px;

                img {
                    height: 100%;

                    @media (min-width: $screen-md){
                        width: 100%;
                    }
                }
            }
        }
    }

    .tend-left {
        @media (min-width: $screen-md){
            float: left;
        }
    }

    .tend-right{
        @media (min-width: $screen-md){
            float: right;
        }
    }

    .interpreter {
        margin-top: -77px;
        position: relative;
        overflow: hidden;


        @media (min-width: $screen-md){
            max-height: 841px;
        }

        .slick-slider{
            margin-bottom: 0px;
        }

        .image {
            width: 100%;
            background-size: cover;
            min-height: 500px;
            background-position: center center;
        }

        .container {
            width: 100%;
            z-index: 3;
            padding: 110px 0px;

            @media (min-width: $screen-md){
                position: absolute;
                top: 36%;
                padding: 0px;
            }

            @media (min-width: 1700px){
                top: 48%;
            }

            .text {

                #sub-head-line {
                    display: none;
                }
                @media (min-width: 500px){
                    #sub-head-line {
                        display: block;
                    }
                }

                h2 {
                    color: $text-color-light;
                    font-size: 40px;
                    font-weight: 550;

                    letter-spacing: -0.7;
                    margin-bottom: 15px; //0px;
                    text-align: center;
                    @include text-shadow(0 2px 4px rgba(0, 0, 0, 0.5));

                    @media (min-width: $screen-sm-min){
                        font-size: 55px; //32px;
                        letter-spacing: -0.8px;
                    }
                }

                p {
                    color: $text-color-light;
                    font-size: 20px;
                    letter-spacing: -0.4px;
                    text-align: center;
                    @include text-shadow(0 2px 4px rgba(0, 0, 0, 0.5));

                    @media (min-width: $screen-sm-min){
                        font-size: 28px;
                        letter-spacing: -0.5px;
                        line-height: 40px;
                    }
                }

                p.last {
                    color: $text-color-light;
                    font-size: 14px;
                    letter-spacing: -0.4px;

                    @media (min-width: $screen-sm-min){
                        font-size: 28px;
                        letter-spacing: -0.7px;
                        line-height: 70px;	
                    }
                }
            }
        }

        .band {
            width: 100%;
            height: 112px;
            opacity: 0.6;
            background-color: transparent; //white;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 3;

            pointer-events: none;

            ul {
                display: block;
                width: 320px;
                list-style: none;
                margin: 0 auto;
                padding: 0;
                margin-top: 2px;

                li {
                    pointer-events: all;
                    margin: 0 5px;
                    float: left;
                }
            }
        }

        .form{

            form {
                .form-group{
                    padding: 0 10px;
                    margin: 0 0 20px;
                    .form-input{
                        width: 270px;
                        height: 5rem;


                    }
                }
                md-autocomplete{
                    height: 53px;

                    md-autocomplete-wrap{
                        input{
                            height: 53px;
                            font-size: 16px;
                            color: white; //$background-base;
                            //opacity: 0.7; 
                            //Extension:

                        }
                    } 
                }

                .home_selctbox{
                    //@include box-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
                    @include border-radius(2px);
                    @media (max-width: 360px){
                        margin: 0 10%;
                        width: 80%;
                        //opacity:0.1;
                    }
                    @media (min-width: 361px){
                        width: 270px;
                        background-color:transparent;
                        //opacity:0.1;

                    }
                    span{
                        &:after{
                            color: #2aada7; //#cb2d75;
                            border-left: 0px;
                            top: -1px;
                            opacity:0.1;

                        }
                    }
                }

            }

        }
    }

    .img-specification {
        position: relative;
        .ico {
            position: absolute;
            bottom: 0;
            left: 40%; //35%
        }
    }

    .oroboo {
        background: url($s3_path + "/assets/images/oroboo.png") no-repeat 0 0;
        background-size: contain;
        width: 134px;
        height: 41px;
        vertical-align: text-bottom;
        display: inline-block;
        position: relative;
        overflow: hidden;
        text-indent: -999px;
    }

    .jumbo {
        h2 {
            padding-top: 50px;
        }
        .text {
            position: absolute;
            bottom: 30px;
            font-size: 18px;
            letter-spacing: -0.5px;
            padding: 0 20px;
            text-align: center;
            width: 100%;
            color: $text-color-light;
        }
    }

    .definition {
        width: 100%;
        padding-top: 48px;
        padding-bottom: 53px;

        .specification {
            h3 {
                color: #273c4c;
            }
            .img-specification {
                .ico {
                    left: 50%;
                    &.ico-video-audio {
                        margin-left: -34px;
                    }
                    &.ico-practice-translate {
                        margin-left: -34px;
                    }
                    &.ico-semi {
                        margin-left: -29px;
                    }
                    &.ico-long-checklist {
                        margin-left: -26px;
                    }
                    &.ico-plus-5min {
                        margin-left: -26px;
                    }
                    &.ico-coins-with-number-1 {
                        margin-left: -40.05px;
                    }
                    &.ico-earth {
                        margin-left: -34px;
                    }
                }
            }
            .text-specification {
                p {
                    padding: 0px;
                }
            }
        }

        .text{
            display: block;
            margin: auto;
            padding-bottom: 46px;
            max-width: 1024px;	

            h2{
                margin-top: 0px;
                margin-bottom: 28px;
                text-align: center;
                font-size: 20px;
                letter-spacing: -0.5px;
                color: $background-default;

                @media (min-width: $screen-sm-min){
                    font-size: 40px;
                    line-height: 0.8;
                    letter-spacing: -1.1px;
                }
            }

            p {
                text-align: center;
                font-weight: $font-light;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.5px;
                color: $background-base;

                @media (min-width: $screen-sm-min){
                    font-size: 20px;
                    line-height: 1.6;
                }
            }
        }

        .images {
            margin-bottom: 80px;
            img {
                width: 100%;
            }
            .image-one, .image-two, .image-three, .image-four {
                padding: 0px;
                position: relative;
                overflow: hidden;
                max-height: 152px;

                @media (min-width: $screen-sm-min){
                    max-height: 240px;
                }
            }
        }
    }

    .easy {
        padding-bottom: 78px;

        .jumbo{
            background: #eff1f2;
            box-shadow: inset 0 0 33px 0 rgba(39, 60, 76, 0.0);
            border: solid 1px #e2e2e2;
            height: 197px;

            &:after {
                border-top: 42px solid #eff1f2 !important;
            }

            h2 {
                color: $default-base; //$default-dark;
                font-weight: 100;
            }
        }
        .text {
            p {
                text-align: center;
                font-weight: $font-light;
                font-size: 24px;
                line-height: 1.6;
                letter-spacing: -0.6px;
                color: $background-default;
                margin-bottom: 70px;

                @media (min-width: $screen-sm-min){
                    margin: 0px 150px 70px;
                }
            }
        }

        .specification {
            margin-bottom: 38px;
            h3 {
                line-height: 1.3;
            }
            .img-specification {
                height: 146px;
                margin-bottom: 32px;
            }
            .text-specification {
                p {
                    padding: 0 10px;
                }
            }
        }

        .btn {
            width: 263px;
            background-color: $default-base; //$default-dark;

            @media (min-width: $screen-sm-min){
                width: 400px;
                margin: 50px auto;
            }

            &:hover {
                border: 1px solid $default-base; //$default-base;
                color: $default-base; //$default-base;
                background: transparent;
                //background-color: $default-base; //$background-default;
            }
        }
    }

    .personal {
        //padding-bottom: 78px;

        .jumbo{
            background: #eff1f2;
            box-shadow: inset 0 0 33px 0 rgba(39, 60, 76, 0.0);
            border: solid 1px #e2e2e2;
            height: 197px;
            &:after {
                border-top: 42px solid #eff1f2;
            }

            h2 {
                color: $default-base;
                font-weight: 100;
            }
        }
        .text {
            p {
                text-align: center;
                font-weight: $font-light;
                font-size: 24px;
                line-height: 1.6;
                letter-spacing: -0.6px;
                color: $background-default;
                margin-bottom: 70px;

                @media (min-width: $screen-sm-min){
                    margin: 0px 150px 70px;
                }
            }
        }

        .specification {
            margin-bottom: 38px;
            h3 {
                line-height: 1.3;
            }
            .img-specification {
                height: 146px;
                margin-bottom: 32px;
                .ico {
                    left: 50%;
                    margin-left: -73px;
                }
            }
            .text-specification {
                p {
                    padding: 0 50px;
                }
            }
        }
    }

    .price {
        padding-bottom: 38px;

        .jumbo{
            background: #eff1f2;
            box-shadow: inset 0 0 33px 0 rgba(39, 60, 76, 0.0);
            border: solid 1px #e2e2e2;
            height: 197px;
            &:after {
                border-top: 42px solid #eff1f2;
            }

            h2 {
                color: $background-secondary;
            }
        }

        .text {
            p {
                text-align: center;
                font-weight: $font-light;
                font-size: 24px;
                line-height: 1.5;
                letter-spacing: -0.5px;
                color: $background-default;
                margin-bottom: 70px;

                @media (min-width: $screen-sm-min){
                    line-height: 1.6;
                    letter-spacing: -0.6px;
                    margin: 0px 150px 70px;
                }
            }
        }

        .col-md-offset-2 {
            @media (min-width: $screen-md-min){
                margin-left: 15.5%;
            }
        }

        .item { 
            width: 308px;
            height: 515px;
            border-radius: 2px;
            background-color: #f9fafa;
            border: solid 1px #eff1f2;
            @media (max-width: $screen-sm-max){
                margin: 0 auto;
            }

            .ico {
                overflow: hidden;
                text-indent: -999px;
                position: relative;
            }

            .avatar {
                width: 166px;
                margin: 0 auto;
                display: block;
                margin-top: 15px;
                margin-bottom: 25px;

                span {
                    display: block;
                    font-size: 18px;
                    line-height: 1.1;
                    color: $background-base;
                    text-align: center;
                    &.ico {
                        margin-bottom: 10px;
                    }
                }
            }

            .price {
                margin-bottom: 10px;
                padding-bottom: 0;
                i {
                    &.ico {
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                } 

                span{
                    display: block;
                    text-align: center;

                    &.value {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 1.2;
                        color: $background-default;
                    }

                    &.description {
                        font-size: 16px;
                        line-height: 1.3;
                        color: #89959d;
                    }
                }
            }

            .line {
                width: 119px;
                height: 3px;
                margin: 20px auto 0;
                border-top: solid 1px #d8dcdf;
            }
        }

        .context {
            margin-top: 42px;
            width: 300px;
            font-size: 18px;
            color: #89959d;
            text-align: center;
            @media (max-width: $screen-sm-max){
                margin: 42px auto 40px;
            }
        }
    }

    .simple {
        padding-bottom: 64px;

        .photo {
            height: 700px; //472px;
            margin-bottom: 80px;
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .app {

            h3 {
                margin-top: 0px;
                margin-bottom: 28px;
                color: $background-default;
                text-align: center;
                font-weight: 300;
                font-size: 28px;
                @media (min-width: $screen-sm-min){
                    font-size: 40px;
                    line-height: 0.8;
                    letter-spacing: -1.1px;
                }
            }

            p {
                text-align: center;
                display: block;
                margin: auto;
                font-size: 20px;
                line-height: 1.6;
                letter-spacing: -0.5px;
                font-weight: 100;
                color: #364855;
                margin-top: 20px;
                margin-bottom: 20px;
                @media (min-width: $screen-md-min){
                    width: 860px;
                    height: 64px;
                }
                @media (min-width: $screen-sm-min){
                    font-size: 20px;
                }
            }
        }




        .specification {
            margin-bottom: 59px;
            .img-specification {
                height: 111px;
                .ico {
                    left: 50%;
                    &.ico-clock-dollar {
                        bottom: -10px;
                        margin-left: -46px;
                    }
                    &.ico-anywhere {
                        margin-left: -45px;
                    }
                    &.ico-anytime {
                        margin-left: -49px;
                    }
                    &.ico-calendar {
                        margin-left: -46px;
                    }
                }

            }
        }

        .down {
            width: 100%;
            display: block;
            margin: auto;
            padding-top: 26px;
            @media (min-width: $screen-md-min){
                width: 886px;
            }

            h5 {
                font-size: 12px;
                letter-spacing: -0.3px;
                color: #6f7e8a;
                text-align: center;
                margin-top: 0px;
                margin-bottom: 26px;
            }

            ul {
                text-align: center;
            }

            .ico-apple-store {
                width: 120px;
                height: 53px;
            }

            .ico-google-play {
                width: 120px;
                height: 53px;
            }

        }

        .jumbo{
            &:after {
                border-top: 42px solid transparent !important;
            }
        }
    }

    .work {
        padding-bottom: 105px;

        .photo {
            background:#000 url($s3_path +"/assets/images/translator-oroboo-angel-werden-1680x750.jpg") center no-repeat;
            background-size:cover;

            height: 400px; //472px;

            @media (min-width: $screen-sm-min){
                background-image: url($s3_path + "/assets/images/translator-oroboo-angel-werden-1680x750.jpg");
                height:750px;
            }
            margin-bottom: 80px;
            position: relative;
            img {
                display:none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .jumbo{
                border: none;
                h2{
                    padding-top:220px;
                    @media (min-width: $screen-sm-min){
                        padding-top:450px;
                    }

                    font-weight:100;
                    color: $default-base;

                    border:none;
                }
                h4{
                    color: $text-color-h3;
                    font-weight:300;
                    padding-top:5px;
                    padding-bottom:15px;
                }
            }
            .btn-angel{
                margin-top:55px;
                @media (min-width: $screen-sm-min){
                    margin-top:330px;
                }
            }
        }

        .app {

            h3 {
                color: $default-base;
                text-align: center;
            }

            p {
                font-size: 24px;
                letter-spacing: -0.6px;
                color: $background-default;
                font-weight: $font-light;
                line-height: 1.6;
                text-align: center;
                margin-bottom: 50px;
                @media (min-width: $screen-sm-min){
                    line-height: 0.8;
                    margin-top: 20px;
                }
                @media (min-width: $screen-md-min){
                    line-height: 1.6;
                    margin-top: 0;
                }
            }

        }

        .specification {
            margin-bottom: 50px;
            .img-specification {
                height: 115px;
                margin-bottom: 32px;
                .ico-driving-license-color {
                    left: 50%;
                    margin-left: -45px;
                    top: 50%;
                    margin-top: -15px;
                }
                .ico-big-license-color {
                    left: 50%;
                    margin-left: -35px;
                }
                .ico-group-2-color {
                    left: 50%;
                    margin-left: -45px;
                }
                .ico-money-color {
                    left: 50%;
                    margin-left: -48px;
                    top: 50%;
                    margin-top: -15px;
                }
            }
            .text-specification {
                p {
                    padding: 0 10px;
                }
            }
        }

        .buttons {
            width: 100%;
            display: block;
            margin: auto;
            text-align: center;
            @media (min-width: $screen-sm-min){
                width: 520px;
            }

            .btn { 
                display: inline-block;
                margin: 0 10px 10px;
                background: $default-base;
                border: 1px solid transparent;
                @media (min-width: $screen-sm-min){
                    margin: 0 10px;
                    width: 400px;
                }

                &:hover {
                    border: 1px solid $default-base;
                    color: $default-base;
                    background: transparent;
                }
            }
        }

        .jumbo {
            &:after {
                border-top: 42px solid transparent !important;
            }
        }
    }

    .find {	

        .jumbo{
            background: #eff1f2;
            box-shadow: inset 0 0 33px 0 rgba(39, 60, 76, 0.0);
            border: solid 1px #e2e2e2;
            height: 197px;
            &:after {
                border-top: 42px solid #eff1f2;
            }

            h2 {
                color: $default-base;
                font-weight: 100;
            }
        }


        .container {
            .col-md-12, .col-md-4 {
                @media (max-width: 420px){
                    padding: 0;
                }
            }

            .col-md-4 {
                position: relative;
                .text {
                    @media (min-width: $screen-md-min) {
                        position: relative;
                        margin-left: -171.5px;
                        left: 50%;
                    }
                }
            }
        }

        .image {
            width: 221px;
            height: 221px;
            display: block;
            margin: auto;
            @include border-radius(50%);
        } 

        .text {
            width: 286.1px;
            margin: 31.9px auto 30px;

            @media (min-width: $screen-sm-min){
                width: 343.9px;
            }
            @media (min-width: $screen-md-min){
                margin: 0;
            }

            p {
                font-family: $font;
                color: rgba(72, 92, 108, 0.8); //#02b1c7;
                font-size: 15px;
                line-height: 1.3;
                text-align: center;
                display: block;
                margin: auto;
                @media (min-width: $screen-sm-min){
                    font-size: 16px; //18px;
                }
            }

            p.bg-gray {
                background-color: #f9fafa;
                padding: 20px;
                margin-top: 93px;
                position: relative;
                max-width: 278px;
                &:before {
                    content: " ";
                    position: absolute;
                    top: -73px;
                    width: 100%;
                    left: 0;
                    height: 73px;
                    background: url($s3_path + "/assets/images/top-rectangel.svg") no-repeat -3px 0;
                }
            }

            p.middle {
                width: 263px;
                margin-top: 50px;
                //font-style: italic;
                @media (min-width: $screen-md-min){
                    margin-top: 70px;//30px;
                    font-size: 16px; //22px;
                    line-height: 1.3;
                    color: rgba(72, 92, 108, 0.8);//#273c4c;
                }
            }
        }

        .button {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 100px;

            .btn {
                width: 300px;
                background-color: $default-base; //$default-dark;
                margin: 5px 15px;
                display: inline-block;

                &:hover {
                    //background-color: $background-default;
                    border: 1px solid $default-base;
                    color: $default-base;
                    background: transparent;
                }

                &.invert {
                    background: $default-medium; //$default-base;
                    border: 1px solid transparent;

                    &:hover {
                        border: 1px solid $default-medium; //$default-base;
                        color: $default-medium; //$default-base;
                        background: transparent;
                    }
                }
            }

        }

    }

    md-progress-linear.md-default-theme .md-bar,
    md-progress-linear .md-bar{
        background-color: #273c4c;
    }

    md-autocomplete md-autocomplete-wrap md-progress-linear {
        bottom: -16px;
    }

    md-progress-linear.md-default-theme .md-container, 
    md-progress-linear .md-container{
        background-color: #c23967;
    }
    //}
}

.job-waiting {
    padding: 48px;
    padding-bottom: 1px;

    min-height: 350px;
    background-color: #273c4c;
    
    .adsbygoogle {
        height: 300px;
        width: 400px;
        margin: auto;
    }

    h2 {
        font-size: 55px;
        color: #39d095;
        letter-spacing: -.8px;
        font-weight: 550;
    }
    h2, h4 {
        padding: 24px;
        margin-top: 0px;
        text-align: center;
    }
    
    button {
        text-align: center;
        float: none !important;
    }
}



.home-buttons{
    text-align: center;


    .button_now {

        @media (max-width: 360px){
            margin: 0 10%;
            width: 73%;
        }
        @media (min-width: 361px){
            width: 270px;
        }
        @media (min-width: $screen-md){
            width: 560px;
        }

        display: inline-block;

        //@include text-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
        color: #ffffff; 
        //color: #000;
        border: 0px;
        height: 52px;
        font-size: 20px; //19px; //22px;
        font-family: $font;
        outline: none;

        @include transition(opacity .1s);
        @include border-radius(2px);

        border-radius: 2px 2px 2px 2px;

        //@include opacity(0.8);
        background-color: rgba(0,0,0,0.3);  //rgba(0,255,255,0.1); $default-hover; $background-default; 
        border: solid 1px $default-base; //white;
        margin-top: 10px;

        &:hover {
            //@include opacity(1);
            border: 1px solid $default-base; //transparent;
            background-color: #02b1c7; //transparent;
            color: #ffffff !important; 
            text-decoration: none;

        }
    }

    .find_angel_container {
        margin:auto;
        position: relative;
        padding-left: 60px;

        @media (max-width: 360px){
            margin: 0 10%;
            width: 73%;
        }
        @media (min-width: 361px){
            width: 270px;
        }
        @media (min-width: $screen-md){
            width: 560px;
        }

        #type_dropdown {
            background-color: rgba( $default-base, .4 );
            width: 60px;
            border: solid 1px $default-base; //white;
            border-right: none;
        }

        .dropdown {
            position: absolute;
            top:10px;
            margin-left: -60px;

            ul li a {
                color: white;
                cursor: pointer;
                &:hover {
                    background-color: lighten($default-base, 5%);
                }
            }

            .dropdown-menu {
                border: none;
                background-color: $default-base;
                margin: 0px;
                margin-top: -2px;
            }
        }

        button{

            display: inline-block;

            //@include text-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
            color: #ffffff; 
            //color: #000;
            border: 0px;
            height: 52px;
            font-size: 20px; //19px; //22px;
            font-family: $font;
            outline: none;

            @include transition(opacity .1s);
            @include border-radius(2px);
        }

        .button_now {
            padding-right:60px;
            width: 100%;

            border-radius: 0px 2px 2px 0px;

            //@include opacity(0.8);
            background-color: rgba(0,0,0,0.3);  //rgba(0,255,255,0.1); $default-hover; $background-default; 
            border: solid 1px $default-base; //white;
            border-left: none;
            margin-top: 10px;

            &:hover {
                //@include opacity(1);
                border: 1px solid $default-base; //transparent;
                background-color: #02b1c7; //transparent;
                color: #ffffff !important; 
                text-decoration: none;

            }
        }

        .button_schedule {
            @include opacity(0.70);
            background-color: $background-default;
            border: solid 2px $background-default;

            &:hover {
                @include opacity(0.85);
            }
        }
    }
}