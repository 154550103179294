// CONTENT
// ------------------------------------
.page-content {
    .content {

        padding: 0px;
        padding-top: 75px;
        padding-bottom: 72px;

        @media (min-width: $screen-md-min){
            padding-bottom: 120px;
        }

        .text {
            font-family: $font;

            h2 {
                font-family: $font;
                font-size: 40px;
                letter-spacing: -1.1px;
                color: $text-color-default;
                margin-top: 0px;
                margin-bottom: 45px;

                @media (min-width: $screen-md-min){
                    line-height: 0.8;
                    height: 30px;
                }
            }

            h3 {
                font-family: $font;
                font-size: 32px;
                line-height: 0.6;
                color: #a4adb4;
                margin-top: 55px;
                margin-bottom: 27px;
            }

            p {
                font-size: 20px;
                line-height: 1.4;
                letter-spacing: -0.5px;
                color: $text-color-content;

                a {
                    color: #314655;
                }

                &.credits {
                    font-size: 16px;
                    line-height: 1.6;
                }
            }

            ul {

                margin-bottom: 62px;

                li {

                    color: $default-base;

                    p {
                        font-size: 14px;
                        line-height: 1.4;
                        color: $default-base;	
                    }

                }

            }

            hr {
                margin-top: 63px;
                border-top: solid 1px #d8dcdf;
            }

        }

    }

    .sidebar {
        margin-top: 0px;
    }

    .default {
        .text {
            h3 {
                font-size: 20px;
                line-height: 1.4;
                color: #89959d;
                margin-bottom: 10px;
            }
            h4 {
                font-size: 24px;
                line-height: 1.4;
                color: #526370;
            }
            p {
                color: $background-default;
                font-size: 16px;
                line-height: 26px;
            }
            ul {
                li {
                    font-size: 16px;
                    line-height: 26px;
                    color: $background-default;
                }
            }
        }
    }

    .about, .feedback, .faq {
        h3 {
            margin-bottom: 30px !important;
        }
    }

    .faq {
        h4 {
            margin-bottom: 20px;
        }
        .highlight
        {
            background-color: #c23967;
            color: #fff;
        }
        .text {

            .search {
                margin-bottom: 70px;
                position: relative;

                input {
                    height: 52px;
                    width: 100%;
                    background-color: #ffffff;
                    border: solid 1px #b4bbc0;
                    font-size: 16px;
                    color: #526370;
                    padding: 17px 15px;
                    @include border-radius(2px);
                }

                button {
                    border: none;
                    position: absolute;
                    top: 0;
                    right: 15px;
                    width: 57px;
                    height: 52px;
                    text-indent: -999px;
                    overflow: hidden;
                    background: url($s3_path + "/assets/images/icon-lupa.png") no-repeat center center;
                    background-color: #e91e63;
                    @include border-radius(2px);
                }
            }
            .number {
                padding: 25px 15px;
                background-color: #eff1f2;
                display: block;
                width: 100%;
                height: 230px;
                p {
                    text-align: center;
                    font-size: 24px;
                    line-height: 1.2;
                    color: $background-default;
                    font-weight: 100;
                    &.option {
                        margin-top: 30px;
                    }
                    strong {
                        font-size: 24px;
                        line-height: 1.0;
                        font-weight: 600;
                        color: $background-default;
                    }
                }
            }
            .empty {
                display: none;
            }
            .no-data {
                > .empty{
                    display: block;
                    color: #89959d;
                    font-size: 14px;
                }
            }
            ul {
                color: #c23967;
                padding-left: 30px;
                li {
                    color: #c23967;
                    padding-left: 10px;
                    line-height: 19px;
                    margin-bottom: 10px;
                    &.is-hidden
                    {
                        display: none;
                    }
                    &.is-active {
                        display: block;
                    }

                    a {
                        font-size: 14px;
                        line-height: 1.4;
                        color: #c23967;
                        .highlight
                        {
                            background-color: #b8e986;
                            color: #333;
                        }
                    }
                }

                &.text {	
                    color: $background-default;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    li {
                        color: $background-default;
                        a {
                            color: $background-default;
                        }
                    }
                }
            }

            .item {
                h3 {
                    margin-top: 40px;
                    margin-bottom: 10px !important;
                }
            }
        }
    }

    .feedback {
        .form-container {
            background-color: transparent;
        }
        form .form-group {
            text-align: left;
        }

        form textarea {
            min-height: 150px;
        }
    }

    .browsers{
        h2{
            margin: 45px 0 18px 0 !important;
            font-size: 32px !important;
        }
        .browsers_links{
            a{
                width: 55px;
                height: 55px;
                display: inline-block;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 10px;

                &.chrome{
                    background-image: url($s3_path + "/volunteer/browser_logos/chrome.png");
                }
                &.firefox{
                    background-image: url($s3_path + "/volunteer/browser_logos/firefox.png");	
                }
                &.opera{
                    background-image: url($s3_path + "/volunteer/browser_logos/opera.png");
                }
                &.safari{
                    background-image: url($s3_path + "/volunteer/browser_logos/safari.png");
                }
                &.edge{
                    background-image: url($s3_path + "/volunteer/browser_logos/edge.png");
                    width: 47px;
                    height: 50px;
                }
            }
        }
    }

    .download_apps{
        h2{
            margin: 45px 0 18px 0 !important;
            font-size: 32px !important;
        }

        .download_apps_links{
            a{
                display: inline-block;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 10px;
                &.google_play{
                    width: 140px;
                    height: 46px;
                    background-image: url($s3_path + "/volunteer/browser_logos/googleplay.png");
                }
                &.itunes{
                    width: 130px;
                    height: 46px;
                    background-image: url($s3_path + "/volunteer/browser_logos/itunes.png");
                }
            }
        }
    }


}