//openTok
.publisherText{
    position: absolute;
    z-index: 110;
    bottom: 100px;
    text-align: center;
    width:100%;
    color: white;
}
.showLobby{
    height: 100%;
}
.globe_animation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 250px;
    text-align: center;
}

.publisher {
    position: absolute !important;
    z-index: 100;
    bottom: 10px;
    left: 10px;
    width: 160px;
    height:140px;

    .publisherContainer {
        position: relative;

        .small_image{
            height: 140px;
            border-radius: 150px;
            position: absolute;
            left: 0px;
            top: 0px;
        }

        .publisherHolder{
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
        }
    }  
}

.mute_remote{

    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-origin: content-box;
    border: 0px;
    -webkit-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    background: #db4437;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.7);
    outline: none;
    position: absolute;
    z-index: 110;
    right: 10px;
    top: 10px;
    padding-top: 7px;

    text-align: center;

    @media (min-width: $screen-md-min){
        width: 36px;
        height: 36px;
    }

    &.selected{
        display: block !important;
    }

    svg{
        fill: white;
        margin: 0px;
        width: 15px;
        height: 15px;

        @media (min-width: $screen-md-min){
            margin: 0px;
            width: 24px;
            height: 24px;
        }
    }
}

.subscriberContainer{
    .mute_remote {
        position: absolute;
        left: 20px;
        top: 90px;
        z-index: 1000;
    }
}



.rtc{
    background: #1d2932;

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .big_image{
        height: 250px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 150px;
    }


    .header{
        @include box-shadow(0 0 0 0 transparent !important);

        .webrt-header{
            height: 53px;
            //opacity: 0.79;
            background-color: rgba(59, 76, 89, 0.9);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }

        .webrtc-hangup{
            float: left;
            width: 33%;
            text-align: center;
            position: relative;
            z-index: 10;

            button{
                font-family: $font;
                font-weight: bold;
                font-size: 12px;
                color: #65c6bb;
                background: none;
                border: 0px;
                margin: 14px 0 0 0px;

                @media (min-width: $screen-md-min){
                    font-size: 16px;
                }

                &:hover{
                    color: $default-dark;
                }
            }
        }

        .webrt-title{
            width: 100%;
            position: absolute;
            top: 4px;
            text-align: center;

            h2{
                font-family: $font;
                font-weight: bold;
                font-size: 22px;
                color: white;
                line-height: 11px;
            }
        }


    }

}

.main-content-webrtc{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.webrtc{
    background:#131d25;

    .thumbWebrtc{
        transform: scaleX(-1);
    }

    .video-content{
        .remotes-nav{

            background: rgba(29, 41, 50, 0.5);
            position:absolute; 
            left:0px; 
            bottom:0px; 
            width:100%;  

            #remotes{

                .webrtc_thumbnail{
                    margin:3px;
                    position: relative;
                    float:left;
                    background-image: url($s3_path + "/assets/images/person.svg");
                    background-position: center center;
                    background-size: cover;
                    border: white 1px solid;
                    overflow: hidden;
                    text-align: center;
                    height:122px; 
                    width:164px;

                    @media (min-width: $screen-md-min){
                        margin:10px;
                    }
                }

                .webrtc_thumbnail video {
                    width: 100%;
                    cursor:pointer;
                    float: left;
                    margin: 0px;
                    transform: scaleX(1);
                }
            }
        }

    }

}

.remove_video{
    background: none;
}

.videoContainer{
    //height: -webkit-calc(100% + 2px);
    //height: calc(100% + 2px);
    width: -webkit-calc(100% + 2px);
    width: calc(100% + 2px);
    left: -1px; 
    top: -1px; 
    position:absolute; 
    pointer-events: none; 
}

.webrtc-stats{
    position: fixed;
    top: 30px;
    right: -20px;
    margin: 32px;

    @media (min-width: $screen-md-min){
        margin: 50px;
    }

    #webrtc-timer, .webrtc-bandwidth, .webrtc-latency{

        clear: both;
        margin: 0 5px 0 0;
        background: rgba(59, 76, 89, 0.9);
        color: #02b1c7;
        width: 117px;
        display: block;
        padding: 4px 0;
        @include border-radius(2px);
        margin-bottom: 10px;

        h3{
            color:#5e5e5e;
            font-size:20px;
        }  

        timer > span, .minutes-webrtc {
            display:inline-block;
            padding: 3px 11px;
            margin: 0 0 2px 0;
            font-family: $font;
            transform-origin: 50% 50%;
            font-size: 25px;
            font-weight: 500;
            text-align: center;
        }
        
        @keyframes blink {
            from {
                color: #02b1c7;
                transform: scale(1);
            }
            to {
                color: #cb2d75;
                transform: scale(1.1);
            }
        }
        
        @-webkit-keyframes blink {
            from {
                color: #02b1c7;
                transform: scale(1);
            }
            to {
                color: #cb2d75;
                transform: scale(1.1);
            }
        }

        timer > span, .minutes-webrtc-blink {
            animation-name: blink;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }

        .price{
            font-size: 16px;
            border-top: 1px solid #2c373d;
            width: 80px;
            display: inline-block;
            padding: 4px 0 0 0;
        }

        .max-minutes {
            font-size: 12px;
            border-top: 1px solid #2c373d;
            width: 80px;
            display: inline-block;
            margin-top: 6px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #cb2d75;
            font-weight: bold;
        }
    }
}

/**
* not suported browsers safari / explorer


.bigScreen{
  width:850px;
  height:400px;
}
*/

.webrtc_buttons{
    width: 33%;
    float: right;
    text-align: center;
    position: relative;
    z-index: 10;

    button{

        width: 53px;
        height: 53px;
        margin:0 -2px;
        background-repeat: no-repeat;
        background-origin: content-box;
        border:0px;

        background: none;

        outline:none;
        @include opacity(0.6);

        vertical-align: top;

        &.sound{

        }

        &.video{

        }

        &.play{
            span{
                img{
                    vertical-align: inherit !important;
                    margin: 0px;
                    display: inline-block;
                    padding: 0px;
                    border: 0px;
                }
            }
        }

        span{
            padding:5px 0 0 0;

            svg{
                fill: white;
            }
        }

        .obj-selected{
            display:block;
        }

        .obj-not-selected{
            display:none;
        }

        &.selected{
            background-color: rgba(219,68,55,1.0);

            .obj-not-selected{
                display:block;
            }

            .obj-selected{
                display:none;
            }
        }


        @media (min-width: $screen-md-min){
            &.selected, &:hover{
                background-color: rgba(219,68,55,1.0);

                .obj-not-selected{
                    display:block;
                }

                .obj-selected{
                    display:none;
                }
            }
        }

    }
}

.container_border_none{
    border:0px !important;

    video, object{
        width: 162px;
        height: 120px;
    }
}

.guestModal{

    float: left;
    margin:60px 0 0 30px;

    button{
        height: 30px;
        border:0px;
        color: #65c6bb;
        background: none;
        font-weight: bold;
        font-family: $font;
        font-size: 15px;
        text-transform: uppercase;

        &:hover{
            color: $default-dark;
        }
    }

}

.audio_only{
    text-align: center;

    img{
        border-radius:100%;
        max-height: 50%;  
        max-width: 100%; 
        width: auto;
        height: auto;
        position: absolute;  
        top: 0;  
        bottom: 0;  
        left: 0;  
        right: 0;  
        margin: auto;
    }
}

.android-rtc{
    .webrtc_buttons{
        width: 55%;
    }
}

.guests {
    .form-control {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        width: 60%;
    }
    .input-group {
        display: inline-block;
        max-width: 320px;
        vertical-align: middle;
        margin-left: 10px;
    }
    .btn {
        float: left;
        height: 44px !important;
        color: rgba(72, 92, 108, 0.77);
    }
}

.page-job_lobby {
    .toast-info {
        display: none !important;
    }
}

#add_time_button {
    cursor: pointer;
}

#add_time_button.ng-hide {
    
}