// LOADING
// ------------------------------------
#loading-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1015;

  .bar {
    position: absolute;
    background-color: $header-bg;
    z-index: 1015;
  }
  .peg {
    @include box-shadow($header-bg 1px 0 6px 1px);
  }
}