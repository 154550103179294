.finished {

	.oroboo {
		background: url($s3_path + '/assets/images/oroboo.png') no-repeat 0 0;
		background-size: contain;
	    width: 134px;
	    height: 41px;
	    vertical-align: text-bottom;
	    display: inline-block;
	    position: relative;
	    overflow: hidden;
	    text-indent: -999px;
	}

	.photo {
		position: relative;
		height: 934px;
		width: 100%;
		margin-top: -77px;
		background: url($s3_path + '/assets/images/sample_09.jpg') no-repeat 0 0;
		background-size: cover;

		@media (max-width: $screen-xs-max){
			height: 800px;
			background-size: cover;
    	background-position: center;
		}

		.jumbo {
			position: absolute;
			left: 0;
			bottom: 45px;

			&:after {
				border-top: 42px solid transparent;
			}
		}
	}
	
	.title {
	  padding: 0px;
	  padding-bottom: 15px;
	  border-radius: 4px;
	  background-color: rgba(249, 250, 250, 0.9);
	  box-shadow: 0 2px 10px 0 rgba(49, 70, 85, 0.5);
	  margin: 74px auto 0;
	  display: block;

	  @media (min-width: $screen-md-min){
	  	width: 750px;
		}

		h2 {
			float: left;
			width: 100%;
    	font-size: 28px;
		  line-height: 1.1;
			letter-spacing: -1.1px;
			color: $default-hover;
			text-align: center;
    	display: block;
    	margin: auto;
    	margin-bottom: 24px;
    	margin-top: 44px;
		}

		p {
			font-size: 16px;
		  line-height: 1.4;
		  color: $text-color-h3;
			display: block;
    	margin: auto;
    	text-align: center;
    	padding: 0 20px;
	    @media (min-width: $screen-md-min){
  			width: 470px;
				height: 64px;
				padding: 0;
			}
		}
	}

	.email {
		display: block;
		margin: auto;
  	border-radius: 4px;
  	background-color: rgba(59, 76, 89, 0.1);
  	padding-top: 67px;
  	padding-bottom: 67px;

  	@media (min-width: $screen-md-min){
			width: 650px;
  		height: 290px;
		}

  	h3 {
  		line-height: 1.5;
  		letter-spacing: -0.6px;
  		color: #111e28;
  		text-align: center;
  		margin-top: 0px;
	    margin-bottom: 0px;
	    @media (min-width: $screen-md-min){
				font-size: 24px;
			}

  	}

  	p {
  		font-size: 20px;
  		line-height: 1.6;
  		letter-spacing: -0.5px;
  		color: $background-base;
  		text-align: center;
  	}
	}

	.text-app {

		padding-top: 75px;
		padding-bottom: 0px;

		h3 {
			font-family: $font;
  		font-size: 40px;
  		line-height: 0.8;
  		letter-spacing: -1.1px;
  		color: $background-default;;
  		text-align: center;
		}

		p {
  		text-align: center;
    	display: block;
    	margin: auto;
  		font-size: 20px;
  		line-height: 1.6;
  		letter-spacing: -0.5px;
  		font-weight: 100;
  		color: #364855;
  		margin-top: 20px;
  		margin-bottom: 20px;
  		@media (min-width: $screen-md-min){
				width: 860px;
  			height: 64px;
			}
		}
	}

	.down {
		width: 100%;
		display: block;
    margin: auto;
    padding-top: 26px;
    margin-bottom: 50px;
    @media (min-width: $screen-md-min){
			width: 886px;
		}

		h5 {
  		font-size: 12px;
  		letter-spacing: -0.3px;
  		color: #6f7e8a;
  		text-align: center;
  		margin-top: 0px;
  		margin-bottom: 26px;
		}

		ul {
			text-align: center;
		}

		.ico-apple-store {
	    width: 120px;
	    height: 53px;
		}

		.ico-google-play {
	    width: 120px;
	    height: 53px;
		}
		
	}

	.specification {
		margin-bottom: 50px;
		.img-specification {
			height: 115px;
			margin-bottom: 32px;
			position: relative;
			.ico {
				position: absolute;
		    bottom: 0;
		    left: 50%;
			}
			.ico-anywhere {
    		margin-left: -51px;
			}
			.ico-anytime {
				margin-left: -49px;
			}
			.ico-calendar {
				margin-left: -43px;
			}
			.ico-clock-dollar {
				margin-left: -48px;
			}
		}
		.text-specification {
			p {
				padding: 0 10px;
			}
		}
	}

	.buttons {
		width: 100%;
		display: block;
		margin: auto;
		text-align: center;
		@media (min-width: $screen-md-min){
			width: 520px;
		}

		.btn { 
			display: inline-block;
			margin: 0 10px 10px;
			@media (min-width: $screen-md-min){
  			margin: 0 10px;
			}
		}
	}

}