// PROFILE 
// ------------------------------------
.page-profile {

  .button-row {
    &.margin-top {
      margin-top: 30px;
    }
  }

  .width-20 {
    @media (max-width: $screen-xs-max){
      width: 22%;
    } 
  }

  .show-submenu {
    display: block! important;
  }

  .header.logged .navbar .navbar-submenu {
    ul li {
      min-width: 130px;
    }
  }

  .firstname-bubbles {
    position: absolute;
    left: 362px;
    top: -9px;
  }

  .form-container {
    margin: 0px;
    padding: 42px 30px 8px 30px;

    input#firm {
      visibility: hidden;

      &.visiblity_show{
        visibility: visible;
      }
    }
  }

  .standard-lang {
    margin: 35px 0px 30px 0px;
    .std-lang-title {
      margin-bottom: 18px;
    }
    .form-group {
      text-align: left;
    }
  }

}

.profile {

	.secondary-languages-wrapper {
	  select {
	    margin-bottom: 10px;
	  }
	}
	
  .prefix{
    positin: relative;
    select{
      height:47px !important;
    }
    .custom_selctbox{
      height: 47px !important;
      width: 100%;
    }
  }

  .button-row {

    @media (min-width: $screen-lg-min){
      padding: 0px 30px;
    }
    
    .btn-cancel-profile, 
    .btn-finish-profile {
      width: 170px;
      height: 44px;
      margin-bottom: 10px;

      &.btn-license-back{
        width: 200px;
      }

      &.btn-license-finish{
        height: inherit;
        line-height: inherit;
        padding-top: 5px;
        padding-bottom: 5px;
        
        @media (min-width: $screen-md-min){
          height: 44px;
          line-height: 44px;
          padding-top: 0px;
          padding-bottom: 0px;
        }

      }
    }
  }

  .score-profile{
    margin: 0px;
  }

  .star.profile-rating {
    margin-left: -38px !important;
  }

}

.profile-summary {

  .profile-section {

    ul.languages li {
      margin-bottom: 10px;
    }

    .profile-image {
      width: 210px;
      height: 210px;
      border-radius: 50%;
      -moz-border-radius: 50%;
      border: 1px solid #ccc;
      overflow: hidden;
      margin: 15px auto;
      img {
        width: 100%;
        text-align: center;
      }
    }

    .thumb-group {
      padding: 10px;
    }

    .profile-file-thumb {
      margin-top: 10px;
      width: 90px;
      text-align: center;

      .document-icon {
        background: url(/assets/images/document-icon.svg) no-repeat;
        width: 50px;
        height: 50px;
        margin: auto;
      }

      img {
        height: 100px;
      }
      .file-thumb-legend {
        font-size: 10px;
      }
    }
  }

}

.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  overflow: hidden;
}

.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #c23967;
}

.empty-stars:before, .full-stars:before {
  content:"\2605\2605\2605\2605\2605";
  font-size: 14pt;
}