// ICONS
// ------------------------------------
.ico {
	background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.thanks-angel{
	background-image: url($s3_path + "/assets/images/thank-you-angel-bubble.png");
	width: 1140px;
}

.ico-media {
	background-image: url($s3_path + "/assets/images/media.svg");
	width: 70px;
  height: 68px;
}

.ico-video-audio {
	background-image: url($s3_path + "/assets/images/video-audio.svg");
	width: 70px;
  height: 68px;
}

.ico-practice-translate {
	background-image: url($s3_path + "/assets/images/practice-translate.svg");
	width: 70px;
  	height: 68px;
}

.ico-practice-translate {
	background-image: url($s3_path + "/assets/images/practice-translate.svg");
	width: 70px;
  height: 68px;
}

.ico-earth {
	background-image: url($s3_path + "/assets/images/earth.svg");
	width: 65px;
  height: 68px;
}

.ico-semi {
	background-image: url($s3_path + "/assets/images/earth.svg"); //semi.svg
	width: 59px;
  height: 63px;
}

.ico-long-checklist {
	background-image: url($s3_path + "/assets/images/long-checklist.svg");
	width: 48px;
  height: 65px;
}

.ico-coins-with-number-1 {
	background-image: url($s3_path + "/assets/images/interpreter-kostenlos-02.svg"); //interpreter-for-free.svg //interpreter-kostenlos-02.svg //coins-with-number-1.svg
	width: 82px;
  height: 52px;
}

.ico-plus-5min {
	background-image: url($s3_path + "/assets/images/plus-5min.svg"); //interpreter-for-free.svg //interpreter-kostenlos-02.svg //coins-with-number-1.svg
	width: 82px;
  height: 52px;
}

.ico-anywhere {
	background-image: url($s3_path + "/assets/images/anywhere.svg");
	width: 102px;
  height: 111px;
}

.ico-anytime {
	background-image: url($s3_path + "/assets/images/anytime.svg");
	width: 98px;
  height: 98px;
}

.ico-calendar {
	background-image: url($s3_path + "/assets/images/calendar.svg");
	width: 86px;
  height: 86px;
}

.ico-clock-dollar {
	background-image: url($s3_path + "/assets/images/clock-dollar.svg");
	width: 96px;
  height: 96px;
}

.ico-google-play {
	background-image: url($s3_path + "/assets/images/play-store.svg"); //google-play.svg
	width: 158px;
  height: 52px;
}

.ico-apple-store {
	background-image: url($s3_path + "/assets/images/app-store.svg"); //apple-store.svg
	width: 153px;
  height: 53px;
}

.ico-google-play-invert {
	background-image: url($s3_path + "/assets/images/google-play.svg"); //play-store.svg
	width: 148px;
  height: 50px;
}

.ico-apple-store-invert {
	background-image: url($s3_path + "/assets/images/apple-store.svg"); //  app-store-copy.svg
	width: 148px;
  height: 50px;
}

.ico-driving-license {
	background-image: url($s3_path + "/assets/images/driving-license.svg"); 
	width: 106px;
  height: 81px;
}

.ico-driving-license-color {
	background-image: url($s3_path + "/assets/images/interpreter-registrieren.svg"); //driving-license-color.svg
	width: 90px;
  height: 70px;
}

.ico-license {
	background-image: url($s3_path + "/assets/images/license.svg");
	width: 153px;
  height: 53px;
}

.ico-big-license {
	background-image: url($s3_path + "/assets/images/big-license.svg"); 
	width: 90px;
  height: 115px;
}

.ico-big-license-color {
	background-image: url($s3_path + "/assets/images/dolmetscher-anfrage-erhalten-01.svg");// uerbersetzen-anfrage-erhalten-03.svg big-license-color.svg dolmetscher-anfrage-erhalten-02.svg uerbersetzer-anfrage-erhalten-04.svg"
	width: 140px; //70
  height: 70px; //90
  margin: 0 auto;
  display:block;
}

.ico-group-2 {
	background-image: url($s3_path + "/assets/images/als-angel-helfen-interpreter.svg");  //group-2.svg
	width: 120px;
  height: 100px;
}

.ico-group-2-color {
	background-image: url($s3_path + "/assets/images/uebersetzer-bewertung.svg"); //group-2-color.svg
	width: 90px;
  height: 70px;
}

.ico-money {
	background-image: url($s3_path + "/assets/images/money.svg");
	width: 118px;
  height: 71px;
}

.ico-money-color {
	background-image: url($s3_path + "/assets/images/money-color.svg");
	width: 96px;
  height: 58px;
}

.ico-arrow-bottom-down {
	background-image: url($s3_path + "/assets/images/arrow-bottom-down.jpg");
	width: 17px;
  height: 10px;
}

.ico-arrow-bottom-up {
	background-image: url($s3_path + "/assets/images/arrow-bottom-up.jpg");
	width: 17px;
  height: 10px;
}

.ico-close {
	background-image: url($s3_path + "/assets/images/close.svg");
	width: 27px;
  height: 27px;
}

.ico-calendar-micro {
	background-image: url($s3_path + "/assets/images/calendar-micro.svg");
	width: 24px;
  height: 24px;
}

.ico-plus {
	background-image: url($s3_path + "/assets/images/plus.svg");
	width: 26px;
  height: 26px;
}

.ico-not-found {
	background-image: url($s3_path + "/assets/images/not-found.svg");
	background-position: right top;
	width: 504px;
  height: 174px;
  display: block;
  margin: 0 auto;

  @media (max-width: 480px){
    width: 300px;
    background-position: 100px 0;
  }

  @media (min-width: 481px) and (max-width: 840px){
    width: 415px;
    background-position: 160px 0;
  }
}

.ico-group {
	background-image: url($s3_path + "/assets/images/group.svg");
	width: 100px;
  height: 108px;
  display: block;
  margin: 0 auto;
}

.ico-close-o {
	background-image: url($s3_path + "/assets/images/close-o.svg");
	width: 24px;
  height: 24px;
}

.ico-twitter {
	background-image: url($s3_path + "/assets/images/twitter.svg"); //twitter.svg
	width: 56px;
  	height: 56px;
}

.ico-facebook {
	//background-image: url($s3_path + "/assets/images/facebook.svg");
	width: 56px;
  	height: 56px;
}

.ico-key {
	background-image: url($s3_path + "/assets/images/dolmetscher-vermittlung-03.svg"); //key.svg
	width: 146px;
  height: 146px;
}

.ico-phone {
	background-image: url($s3_path + "/assets/images/interpreter-video-chat.svg"); //phone.svg
	width: 146px;
  height: 146px;
}

.ico-list-check {
	background-image: url($s3_path + "/assets/images/list-check.svg");
	width: 146px;
  height: 146px;
}

.ico-semi-professional {
	background-image: url($s3_path + "/assets/images/semi-professional.svg");
	width: 166px;
  height: 166px;
}

.ico-professional {
	background-image: url($s3_path + "/assets/images/professional.svg");
	width: 166px;
  height: 166px;
}

.ico-icon-audio {
	background-image: url($s3_path + "/assets/images/icon-audio.svg");
	width: 38px;
  height: 36px;
}

.ico-icon-video {
	background-image: url($s3_path + "/assets/images/icon-video.svg");
	width: 32px;
  height: 36px;
}

.ico-icon-audio-color {
	background-image: url($s3_path + "/assets/images/icon-audio-color.svg");
	width: 38px;
  height: 36px;
}

.ico-icon-video-color {
	background-image: url($s3_path + "/assets/images/icon-video-color.svg");
	width: 32px;
  height: 36px;
}