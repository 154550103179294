// BUTTONS
// ------------------------------------
.tabs {
	
	.nav {
		padding-top: 46px;

		&.nav-stacked {
			float: left;
			width: 100%;
			@media (min-width: 480px){
				width: 30.78%;
			}
			> li {
				border: 1px solid transparent;

				& + li {
					margin-top: 0;
				}
				&.active, &:hover {
					a {
						background-color: #eff1f2;
						color: $background-base;
						position: relative;
						@include border-radius(0);
					}
				}
				&.active {
					border-top: 1px solid #b4bbc0;
					border-bottom: 1px solid #b4bbc0;
					border-left: 1px solid #b4bbc0;
					a {
						&:after {
							content: " ";
							width: 20px;
							height: 100%;
							background-color: #eff1f2;
							position: absolute;
							right: -10px;
							top: 0;
							z-index: 9;
						}
					}
				}
			}
		}

		li {
			min-height: 90px;
			a {
				padding: 20.5px 15px;
			}

			.time {
				display: block;
				float: left;
				color: $text-color-light;
				text-align: center;    
				line-height: 1;
    		padding-top: 5px;
				width: 40px;
  			height: 40px;
  			margin-right: 15px;
  			@include border-radius(50%);

  			&.red-time {
  				background-color: #e91e63;
  			}

  			&.green-time {
  				background-color: #b8e986;
  			}

  			&.orange-time {
  				background-color: #f5a623;
  			}

  			small {
  				display: block;
  				font-size: 7px;
  			}

  			strong {
  				display: block;
  				font-size: 14px;
  			}
			}
			.content {
				display: block;
				float: left;
				line-height: 1;
				width: 80%;

				strong {
					font-size: 16px;
		  			color: $background-base;
		  			display: block;
		  			font-weight: 400;
				    // white-space: nowrap;
				    // overflow: hidden;
				    // text-overflow: ellipsis;
				    padding-bottom: 1px;
					line-height: 19px;
				}

				.description {
					font-size: 12px;
				  line-height: 1.2;
				  color: rgba(59, 76, 89, 0.7);
				}
			}
		}

	}

	.tab-content {
		width: 100%;
		background-color: #eff1f2;
		padding: 31px 42px;
		border: 1px solid #b4bbc0;

		@media (min-width: $screen-lg-min){
			min-height: 500px;
		}
		
		min-height: 500px;

		.tab-pane {
			width: 100%;
			position: relative;


			h3 {
				font-size: 28px;
	  		line-height: 1.1;
	  		color: $background-default;
	  		margin-top: 0;
	  		margin-bottom: 20px;
			}

			ul {
				float: left;
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					width: 220px;
					margin: 25px 0;
					span {
						display: block;
						font-size: 14px;
					  line-height: 1.1;
					  color: #414e58;
					  font-weight: 100;
					}
					strong {
						display: block;
						font-size: 16px;
					  line-height: 2;
					  color: $background-base;
					  font-weight: 400;
					  padding-left: 22.5px;
					}
				}

				&.actions {
					background-color: white;
					border: 1px solid #e8eaeb;
					height: 42px;
					padding: 0px;
					@include border-radius(5px);
					@media (min-width: $screen-md-min){
						position: absolute;
				    top: 0px;
				    right: 20px;
				  }
		
					li{
						width: auto;
						margin: 0;
				    float: left;
				    border-left: 1px solid #e8eaeb;
				    height: 100%;
						&:nth-child(1) {
							border-left: none;
						}
						a {
							color: $background-secondary;
							font-size: 14px;
							font-weight: 600;
							line-height: 40px;
				    	padding: 0 20px;
							text-transform: uppercase;
							display: block;
						}
					}
				}
			}
		}

	}

	&.tabs-vertical {
		.tab-content {
			float: left;
			width: 100%;
			margin: 30px 0;
			overflow: hidden;

			@media (min-width: 480px){
				width: 69.22%;
				margin: 0;
			}
		}
	}
}