.modal-content{
  @include border-radius(4px);
  padding: 0px 5px;

  .modal-backdrop.in{
    @include opacity(0.8);
  }
  .modal-backdrop{
    background-color: #3b4c59;
  }

  .modal-header{
    border-bottom: 0px;
    margin-top: 30px;
    padding: 0px;

    h3{
      text-align: center;
      color: $default-hover;
      font-size: 28px;
      font-family: $font;
      font-weight: 300;
      margin: 24px 0 0 0;
    }
  }

  .modal-body{

    text-align: center;
    color: rgba(72, 92, 108, 0.77);
    font-size: 16px;
    font-family: $font;
    border: 0px;
    padding: 10px 20px 0px;
  }

  .modal-footer{
    border: 0px;
    text-align: center;
    padding: 40px 10px 50px;

    button, input[type="submit"]{
      display: block;
      height: 44px;
      @include border-radius(2px);
      background-color: $default-hover;
      border:0px;
      color: white;
      font-size: 15px;
      font-family: $font;
      font-weight: bold;

      width: 100%;
      margin: 10px 0;

      &.btn-default{
        background-color: $default-secondary;
        color: white;
        border: solid 1px $default-gray;
        color: $default-gray;

        &:focus, &:hover {
          border: solid 1px $default-gray;
          background-color: transparent;
          color: $default-gray;
        }
      }

      &:focus, &:hover {
        border: 1px solid $default-hover;
        background-color: transparent;
        color: $default-hover;
      }

      &.btn-negative{
        background-color: $default-secondary;
        border: solid 1px $default-gray;
        color: $default-gray;

        &:focus, &:hover {
          border: 1px solid $default-gray;
          background-color: transparent;
          color: $default-gray;
        }
      }
    }

    @media (min-width: $screen-md-min){
      padding: 40px 0 50px 0;
      
      button{
        display:inline-block;
        margin:0 10px;
        width: 218px;
      }
    }
  }


  .guests{


    input[type="text"], input[type="email"]{
      height: 44px;
      line-height:44px;
      font-family: $font;
      font-weight: $font-light;
      color: $text-color-placeholder !important;
      font-size: 16px;
      @include border-radius(6px);
      display: inline-block;
      padding: 0 20px;
      border: solid 1px #ccc;
    }

    .guest-link{
      input[type="text"], input[type="email"]{
        width: 100%;
      }
    }

    form{
      
      input[type="submit"]{
        display: inline-block;
        height: 39px;
        @include border-radius(2px);
        background-color: #2aada7;
        border:0px;
        color: white;
        font-size: 15px;
        font-family: $font;
        font-weight: bold;
      }

      .error{
        font-size: 12px;
      }
    }
  }

  .play_pause_button{
    display: inline-block;
    height: 125px;
    width: 123px;
  }

}
.modal-footer .btn + .btn{
  margin-left: 0px;

  @media (min-width: $screen-md-min){
    margin-left: 5px;
  }
}

.modal-dialog{
  @media (min-width: $screen-md-min){
    width: 700px;
  }
}

.job_profile_complete{
  .score-list{
    h4{
      font-size:14px;
    }
    p{
      text-align: left;
      color: $text-color-h3;
      font-size: 14px;
    }
  }

  .checkbox_stars{
    text-align: left;
    margin-top: 25px;

    .form-input{
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

a.btn_modal{
  margin-top:10px !important;
  @media (min-width: $screen-md-min){
    display: inline-block !important;
    width: 218px !important;
    margin-top:0px;
  }
}

button.score_modal{
  height: 46px;
}

a.score_modal{
  height: 47px;
  margin-top: 2px !important;
}