.job-datetimemodal {

	* {
		outline: none;
	}
	
	.uib-datepicker {
		text-align: center;
		margin-top: 20px;
		font-family: "Roboto", sans-serif;

		table {
			margin: 0 auto;
			text-align: left;
		}

		.uib-title {
			text-transform: uppercase;
			border: 0px solid transparent;
			font-family: Roboto;
			font-size: 23.4px !important;
			font-weight: 700;
			text-align: center;
			color: #526370;
			outline: none;
		}

		.uib-left {
			width: 32.3px;
		    height: 32.3px;
		    border-radius: 32.3px;
		    padding: 0;
		    background-color: #a4adb4;
		    border: 0px solid transparent !important;
		    outline: none;

		    i.glyphicon {
		    	top: -4px;
		    	color: white;
		    }

		    &:hover {
		    	background-color: #3b4c59;
		    }
		}

		.uib-right {
			width: 32.3px;
		    height: 32.3px;
		    border-radius: 32.3px;
		    padding: 0;
		    background-color: #a4adb4;
		    border: 0px solid transparent !important;
		    outline: none;

		    i.glyphicon {
		    	top: -4px;
		    	color: white;
		    }

		    &:hover {
		    	background-color: #3b4c59;
		    }
		}

		small {
			font-weight: 400;
			line-height: 1.54;
			text-align: center;
			color: #89959d;
			text-transform: uppercase;
			margin-top: 10px;
			margin-bottom: 15px;
			display: inline-block;
		}

		.btn {
			font-size: 14.1px;
		}

		.uib-years .btn {
			background-color: #fbfbfb;
			border: solid 0.6px #ffffff;
			outline: none;

			&.active {
				box-shadow: 0 0 0;
			}
			&.btn-info {
				background-color: #39d095;

				span {
					color: white !important;
				}
			}

			&[disabled] {
				background-color: #ccc;
			}

			.text-info {
    			color: inherit;
    		}
		}

		.uib-months .btn {
			background-color: #fbfbfb;
			border: solid 0.6px #ffffff;
			outline: none;

			&.active {
				box-shadow: 0 0 0;
			}
			&.btn-info {
				background-color: #39d095;

				span {
					color: white !important;
				}
			}

			&[disabled] {
				background-color: #ccc;
			}

			.text-info {
    			color: inherit;
    		}
		}

		.uib-day .btn-sm {
			width: 46.9px;
			height: 46.9px;
			background-color: #fbfbfb;
			border: solid 0.6px #ffffff;
			padding: 0px;
			outline: none;

			&.active {
				box-shadow: 0 0 0;
			}
			&.btn-info {
				background-color: #39d095;

				span {
					color: white !important;
				}
			}

			&[disabled] {
				background-color: #ccc;
			}

			.text-info {
    			color: inherit;
    		}
		}
	}

	.uib-timepicker {
		margin: 20px auto 0px auto;
		text-align: left;
		font-family: "Roboto", sans-serif;

		.uib-time input {
			width: 63px;
		    height: 60px;
		    border: solid 1px #d8dcdf;
		    border-radius: 0;
		    font-family: Roboto;
			font-size: 40px;
			font-weight: 400;
			text-align: center;
			color: #526370;
			padding: 0;
			margin: 0;
		}

		.uib-separator {
			width: 11px;
		    height: 53px;
		    font-size: 40px;
		    font-weight: 400;
		    text-align: center;
		    color: #526370;
		    padding-right: 8px;
		}

		.uib-increment .btn-link {
			width: 0; 
			height: 0; 
			padding: 0;
		    border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 12px solid #b4bbc0;
			margin-left: 14px;
			margin-bottom: 12px;
			outline: none;

			&:hover {
				border-bottom-color: #3b4c59;
			}

			.glyphicon {
				display: none !important;
			}
		}

		.uib-decrement .btn-link {
			width: 0; 
			height: 0; 
			padding: 0;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-top: 12px solid #b4bbc0;
			margin-left: 14px;
			margin-top: 12px;
			outline: none;

			&:hover {
				border-top-color: #3b4c59;
			}

			.glyphicon {
				display: none !important;
			}
		}

		.uib-time.am-pm button {
			width: 47px;
			margin-left: 35px;
			border-radius: 1.6px;
			border: 0px solid transparent !important;
			background-color: #d8dcdf;
			font-family: Roboto;
			font-size: 19.2px;
			font-weight: 400;
			text-align: center;
			color: #526370;
			padding: 0;
			outline: none;

			&:hover {
				background-color: #3b4c59;
				color: white;
			}
		}
	}
}