// JOB LIST
// ------------------------------------

.job-details-years-old {
    display: inline!important;
    padding-left: 0px!important;
}

.job-detail-image {
    img {
        margin-left: 30px;
        margin-top: 10px;
        border-radius: 50%;
        height: 100px;
    }
    p { margin-left:  20px; margin-top: 10px; }
}

.valign-middle {
    vertical-align: middle;
}

.job-overview-image {
    float:left;
    img {
        padding: 6px;
        margin-left: 0px;
        margin-top: 0px;
        border-radius: 50%;
        height: 60px;
    }
    p { margin-left:  20px; margin-top: 10px; }
}

.page-job_list, .page-job_create {
    .header {
        height: 98px;
        @media (min-width: $screen-sm-min){
            height: 122px;      
        }

        .navbar {
            @media (max-width: $screen-xs-max){
                height: 45px;
            }

            .width-50 {
                @media (max-width: $screen-xs-max){
                    width: 48%;
                }    		
            }
            .width-30 {
                @media (max-width: $screen-xs-max){
                    width: 30%;
                } 
            }

            .width-20 {
                @media (max-width: $screen-xs-max){
                    width: 20%;
                } 
            }

            .navbar-header{
                .navbar-brand {
                    .oroboo {
                        @media (max-width: $screen-xs-max){
                            //background: url($s3_path + '/assets/images/oroboo-logo.svg') no-repeat 0 0 !important;
                            //width: 38px;
                            //height: 38px;
                            margin-top: 7px;
                        }
                    }
                }

                .navbar-toggle {
                    margin-top: 8px;
                }

                .logged-menu {
                    margin-top: 8px;
                    @media (min-width: $screen-sm-min){
                        margin-top: 18px;
                    }
                }

                .default-menu {
                    margin-top: 94px;
                    @media (min-width: $screen-sm-min){
                        margin-top: 20px;
                    }
                    &.open {
                        @media (min-width: 768px) and (max-width: 991px){
                            top: 98px;
                        }
                    }
                }
            }
            .navbar-submenu {
                display: block !important;
                box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);	    	
                li {
                    a {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }
                
                li:nth-child(4) {
                    float: right;
                    color: $default-base;
                    &:hover::before {
                        border-bottom: none;
                    }
                }
                
                li:nth-child(2), li:nth-child(3) {
                    float: right;
                    &:hover::before, &.active::before {
                        border-bottom: 3px solid $default-base;
                    }
                    a {
                        color: $default-base;
                    }
                }

                .create-job {
                    float: right;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    @media (min-width: $screen-sm-min){
                        color: white;
                        text-align: right;
                    }
                    .ico {
                        vertical-align: middle;
                        margin-right: 5px;
                        #plus {
                            fill: $background-menu !important;
                        }
                    }
                }
            }
            &.menu-show {
                .navbar-submenu {
                    @media (max-width: $screen-xs-max){
                        position: absolute;
                        top: 50px;
                        width: 100%;
                    }
                }
            }
        }   
    }
    .page {
        padding: 102px 0 0;
        @media (min-width: $screen-sm-min){
            padding: 122px 0 0;
        }
    }

    .job-list-pagination {
        top: 1000px;
        width: 30%;
        .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
            color: #FFFFFF;
            background-color: #314655;
            border-color: #314655;
        }
        .pagination > li > a, .pagination > li > span {
            color: #314655;
        }
    }
}

.job-list{
    min-height: 350px;
    .container{
        position: relative;	
    }
    .btn-refresh-list {
        position: absolute;
        top: 14px;
        right: 25px;
        z-index: 100;
    }

    .not-found {
        height: 410px;
        padding: 30px 0;
        background-color: rgba(232, 234, 235, 0.7);

        .margin-top {
            margin-top: 50px;
        }

        h3 {
            text-align: center;
            font-size: 22px;
            font-family: "Roboto", sans-serif;
            color: #abb3ba;
            font-weight: 600;
            margin: 30px 0px 5px;
        }

        p {
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            color: #abb3ba;
            text-align: center;

            a {
                color: $default-medium;
            }
        }
    }

    .title {
        position: absolute;
        font-size: 12px;
        top: 22px;
        left: 15px;
    }

    .job-list-tabs .table {
        margin-top: 20px;
    }

    .modal-footer-buttons{
        position:inherit !important;
    }

    .done {
        .time {
            strong {
                display: none !important;
            }
        }
    }

}

.list{
    strong{
        position: relative;
    }
    .sign{
        display: inline-block !important;
        height: 18px;
        width: 18px;
        background-position: center center !important;
        background-size: cover;
        // position: absolute;
        // top: calc(50% - 10px);
        // margin-left: 5px;
    }
}
