.register{

  h2{
    text-align: center;
    font-size: 40px;
    color: $text-color-default;
  }

  form{

    margin: 40px 0 0 0;

    .form-group{

      .form-input > input{
        @include border-radius(2px);
      }

      md-radio-button{
        .md-label{
          color: $text-color-placeholder;
        }

      }

    }

    .form-fotter{
      button{
        font-weight: normal;
        border: 1px solid $background-secondary;

        @include border-radius(2px);

        &:hover{
          background-color: white;
          color: $background-secondary;
        }
      }

      .text{
        p{
          color: $text-color-base;
        }
      }
    }

    .form-link{
      text-align: center;
    }

  }


}

.register-checkbox{
  md-checkbox{
    width: 100%;
    margin: 10px 0 0 0;
    color: $text-color-placeholder;

    &.md-checked{
      .md-icon {
        background-color: $background-secondary !important;
      }
    }

    .md-icon {
      border-color: $background-secondary !important;
    }
  }
}

.pop-up-register{
  .register-checkbox{
    md-checkbox{
      color: white;
    }
  }
}

.only-register{

  padding: 0 5%;

  .login{
    display:none;
  }
}
