// WRAPPER
// ------------------------------------
/*.wrapper {
  .wrapper-content {
    display: none;
  }

  &.show {
    .wrapper-content {
      display: block !important;
    }
  }
}*/

#loading-bar-spinner {
  position: absolute;
  z-index: 1010;

  .spinner-icon {
    position: relative;
    border-top-color: $header-bg;
    border-left-color: $header-bg;
    z-index: 1015;
  }
}

.bg-loading {
  display: none;
  position: fixed;
  background-color: $background-bg;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1009;

  @include opacity(0.9);

  .message {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    padding-top: 60px;
    height: 30px;
    margin: -45px 0 0 -75px;
  }
}
