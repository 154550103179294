// MIXINS
// ------------------------------------
//shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
     -moz-box-shadow: $shadow;
      -ms-box-shadow: $shadow;
       -o-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow; // iOS <4.3 & Android <4.1
     -moz-text-shadow: $shadow;
      -ms-text-shadow: $shadow;
       -o-text-shadow: $shadow; /* Opera */
          text-shadow: $shadow;
}

@mixin rotate($deg...) {
  -webkit-transform: rotate($deg); /* Safari and Chrome */
     -moz-transform: rotate($deg); /* Firefox */
      -ms-transform: rotate($deg); /* IE 9 */
       -o-transform: rotate($deg); /* Opera */
          transform: rotate($deg);
}

@mixin transition($value...) {
  -webkit-transition: $value;
     -moz-transition: $value;
       -o-transition: $value;
          transition: $value;
}

// Opacity
@mixin opacity($opacity) {
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
  opacity: $opacity;
}

// Radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}