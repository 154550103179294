// JUMBO
// ------------------------------------
.jumbo {
	position: relative;
	background: transparent;
	border-top: 1px solid white;
	//border-bottom: 1px solid white;
	height: 205px;
	width: 100%;
	//margin-bottom: 105px;
	text-align: center;
	margin-bottom: 70px;
	color: $default-base !important; 	
	font-weight: 100 !important;
	@media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
		padding-left: 20px;
    padding-right: 20px;
  }

	&:after {
		content: " ";
		position: absolute;
		bottom: -42px;
		left: 50%;
		margin-left: -40px;
		width: 0;
    height: 0;
    border-top: 42px solid #5bc39a;
    border-left: 43px solid transparent;
    border-right: 43px solid transparent;
	}

	}
	h3, h4 {
		color: $text-color-light;
	}
