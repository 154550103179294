.progressbar{
  text-align: center;
  
  ul{
    margin:20px 4%;

    background-color: #ffffff;
    
    list-style: none;
    display:inline-block;

    li{
      text-align: left;
      padding: 13px 0 7px 0;

      @media (min-width: $screen-md-min){
        text-align: center;
      }

      .part-content-progressbar{
        @include border-radius(50%);
        border: 1px dotted rgba(134, 150, 169, 0.63);
        display: inline-block;
        width: 24px;
        height:23px;
        text-align: center;
        font-size: 14px;
        color: rgba(134, 150, 169, 0.63);

        .icon{
          display: none;
        }
        .number{
          display: block;
        }
      }
      p{
        font-size: 11px;
        display: inline-block;
        color: rgba(134, 150, 169, 0.63);
        margin-left:5px;
      }

      &.selected{
        cursor: pointer;
        
        .part-content-progressbar{
          border: none;

          .icon{
            display: inline-block;
          }
          .number{
            display: none;
          }
        }

        p{
          color: $default-medium;
        }
      }


      &.almost{
        .part-content-progressbar{
          color: $default-medium;
          border: 1px dotted $default-medium;

          .icon{
            display: none;
          }
          .number{
            display: block;
          }
        }

        p{
          color: $default-medium;
        }
      }
    }
  }
}

.title-payment{
  .form-input{
    @media (min-width: $screen-md-min){
      width: 100% !important;
      display: block !important;
      max-width: 100% !important;
    }
  }
}

#formsemiprofessional {
  .btn-cancel-profile, 
    .btn-finish-profile {
      width: 100%;
  }
}

#formprofessional {
  .text-left {
    @media (min-width: $screen-md-min){
      //text-align: left;
      //padding-left: 81px;
    }
  }

  .btn-cancel-profile, 
    .btn-finish-profile {
      width: 100%;
    }

  .medium-left {
    @media (min-width: $screen-md-min){
      //padding-left: 47px;
    }
  }

  .license_form {
    margin-bottom: 0px;
  }
}

.text-left-form {
  text-align: left;
}

.license-checkboxes{
  margin: 0 10px 0 10px;
  text-align: left;
}