/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */

//Colors

$default-light: 		#b8e986;
$default-medium: 		#39d095;
$default-base: 			#02b1c7;
$default-dark: 			#dc437d;
$default-hover: 		#c23967;
$default-secondary: 	#eff1f2;
$default-gray: 			#b4bbc0;

$text-color-base:					#414e58;
$text-color-light:  			#ffffff;
$text-color-normal:  			#c3c3c3;
$text-color-placeholder: 		#8696a9;
$text-color-form: 				#c3c3c3;
$text-color-menu: 				#ffffff;
$text-color: 							#72828c;
$text-color-h3: 					#273c4c;
$text-bubble:             #229a6b;
$text-color-error:        #DD4C39; //rot-orange //#eb417a; magenta
$text-color-default:      #657480;
$text-color-content:      #89959d;
$text-form: 				#000000;

$background-default: #273c4c;
$background-secondary: #2bc287;
$background-base: #3b4c59;
$background-menu: $background-secondary;

$line-base:				#243440;

$green-select: #2aada7;

$navbar-inverse-link-color: #5AADBB;
$header-bg: #51445f;
$background-bg: #f0f0f0;
$background-dark-green: #4b5f6f;
$icon-font-path: "../../bower_components/bootstrap-sass/assets/fonts/bootstrap/";


$font: 'Helvetica', 'Arial', sans-serif;
//$font: 'Roboto Slab', sans-serif;
$font-light: 300;

$s3_path: "https://s3.eu-central-1.amazonaws.com/oroboo";