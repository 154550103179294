// Header
// ------------------------------------
.footer {
  padding-top: 30px;
  background-color: $background-default; //#19242C; #20303c; 
  min-width: 320px;
  @media (min-width: $screen-sm-min){
    padding-top: 75px;
  }

  h6 {
    text-align: center;
    font-weight: 100;
    font-size: 16px;
    color: $text-color-light;

    .oroboo {
      background: url($s3_path + '/assets/images/oroboo.png') no-repeat 0 0;
      background-size: contain;
      width: 67px;
      height: 19px;
      color: $background-base;
      vertical-align: text-bottom;
      display: inline-block;
    }
  }

  hr {
    border-top: solid 1px $line-base;
  }

  .oroboo {
    line-height: 1.0;
    background: url($s3_path + '/assets/images/oroboo.png') no-repeat 0 0;
    background-size: contain;
    width: 150px;
    height: 37px;
    position: relative;
    overflow: hidden;
    text-indent: -999px;
  }

  .arrow-open {
    width: 37px;
    height: 26px;
    opacity: 0.5;
    border-radius: 4px;
    border: dashed 1px $default-light;
    float: right;
    text-align: center;
    @media (min-width: $screen-sm-min){
      display: none;
    }
  }

  .description {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
      float: left;
      min-width: 50%;
    }

    &.open {
      .text {
        display: block !important;
      }

      .arrow-open {
        span {
          @extend .ico-arrow-bottom-up;
        }
      }
    }

    .text {
      width: 100%;
      display: none;
      @media (min-width: $screen-sm-min){
        width: 305px;
        display: block;
      }
    }

    p {
      font-weight: 100;
      font-size: 16px;
      line-height: 1.6;
      color: $text-color-light;

      &.title {
        font-weight: 300;
        font-size: 24px;
        line-height: 0.8;

        .oroboo, .about {
          float: left;
        }

        .about {
          height: 40px;
          padding-top: 13px;
          margin-right: 10px;
        }
      }
    }

  }

  .links {
    margin-top: 40px;
    @media (min-width: $screen-sm-min){
      margin: 45px 0 0;
    }

    &.open {
      .items {
        display: block !important;
      }
      .arrow-open {
        span {
          @extend .ico-arrow-bottom-up;
        }
      }
    }

    .other-menus {
      display: block;
      font-size: 16px;
      color: $default-medium;
      margin-bottom: 10px;
      @media (min-width: $screen-sm-min){
        display: none;
      }
      a {
        color: $default-medium;
      }
    }

    .items {
      display: none;
      @media (min-width: $screen-sm-min){
        display: block;
      }
    }

    ul {
      margin: 0 0 0 15px;
      padding: 0;
      list-style: none;
      @media (min-width: $screen-sm-min){
        margin: 0;
        min-width: 180px;
        float: left;
      }

      &.space-top {
        @media (max-width: $screen-xs-max){
          margin-top: 20px;
        }
      }

      li {
        a {
          font-weight: 300;
          font-size: 16px;
          color: $default-base; //$default-medium; $default-light; 
          line-height: 2;

          &:hover {
            color: white;
          }
        }

        .social {
          margin-top: 10px;
          display: block;
          @media (min-width: $screen-sm-min){
            margin-top: 30px;
          }

          a {
            margin-right: 20px;
            text-indent: -9999px;
            position: relative;
            overflow: hidden;
          }
        }
      }
      
    }

    .dropdown-menu {
      li {
        a {
          &:hover{
            color: $background-default;
          }
        }
      }
    }
  }

  .languages-footer .dropdown {
    @media (min-width: $screen-md-min){
      display: none;
    }
  }

}