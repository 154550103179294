.login {

  position: inherit;

  @media (min-width: $screen-md-min) {
    position: relative;
  }

  .login_box {
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 659px;
    top: 73px;
    left: 0px;
    right: inherit;
    position: absolute;
    z-index: 100;
    background-color: $background-default;

    @media (min-width: $screen-md-min) {
      width: 480px;
      height: 659px;
      top: 60px;
      left: inherit;
      right: 0px;
    }

    &.show-hide {
      -webkit-transition: all linear 0.15s;
      transition: all linear 0.15s;
    }

    &.show-hide.ng-hide {
      opacity: 0;
    }

    h2 {
      font-family: $font;
      font-weight: $font-light;
      font-size: 28px;
      color: $background-secondary;
      margin: 50px 60px 0;
    }

    .login_close {
      color: $default-light;
      font-size: 30px;
      position: absolute;
      top: 15px;
      right: 23px;
      outline: none;
    }

    .alerts {
      margin-bottom: 20px;

      .container {
        width: 100%;

        .alert {
          @include border-radius(6px);
        }
      }
    }

  }

  a {
    cursor: pointer !important;
  }

  &.login_register {
    .form-group {
      margin: 0 0 10px 0 !important;
    }
  }

  .help-text {
    text-align: center;
    font-family: Asap;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    color: #657480;
  }

}

.forgotten-link{
  a{
    font-size:16px !important;
    text-decoration: none !important;
  }
}

.text-centered p{
  text-align: center !important;
}

#formLogin {
  .alert{
    &.register {
      display: none;
    }
  }
}

#formRegister {
  .alert{
    &.login {
      display: none;
    }
  }
}

.only-login{
  padding: 0 5%;

  .register{
    display:none;
  }
}

.social-box{

  .title{
    padding: 50px 0px 33px;
    .line{
      display: inline-block;
      position: relative;
      top: -2px;
      width: 68px;
      border-top: solid 1px #d8dcdf;
    }

    .text{
      padding: 10px;
      font-size: 1.15em;
    }
  }
}

.forgot {
  min-height: 370px;
  margin-top: 50px;
}