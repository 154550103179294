/**
 * Fonts
 */
@import "https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic";
@import "https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,100,700";
@import "https://fonts.googleapis.com/css?family=Asap:400,400italic,700,700italic";

@import "styles/variables.scss";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/bc-css-flags/dist/css/bc-css-flags.scss";
// endbower

/**
 * 
 */
@import "styles/mixins.scss";
@import "styles/global.scss";

/**
 * Components
 */
@import "styles/components/alerts.scss";
@import "styles/components/typograph.scss";
@import "styles/components/icons.scss";
@import "styles/components/icomoon.scss";
@import "styles/components/jumbo.scss";
@import "styles/components/specification.scss";
@import "styles/components/loading.scss";
@import "styles/components/header.scss";
@import "styles/components/footer.scss";
@import "styles/components/wrapper.scss";
@import "styles/components/steps-circle.scss";
@import "styles/components/buttons.scss";
@import "styles/components/sidebar.scss";
@import "styles/components/stars.scss";
@import "styles/components/form-container.scss";
@import "styles/components/tabs.scss";
@import "styles/components/modal.scss";
@import "styles/components/inside_galery.scss";
@import "styles/components/content.scss";
@import "styles/components/volunteer.scss";

/**
 * Pages
 */
@import "webrtc/webrtc.scss";
@import "job/job-list.scss";
@import "job/job-create.scss";
@import "job/job-create-modals.scss";
@import "profile/profile.scss";
@import "login/login.scss";
@import "home/home.scss";
@import "register/finished.scss";
@import "license/vip.scss";
@import "register/register.scss";
@import "chat/chat.scss";

@import "skylink/skylink.scss";

